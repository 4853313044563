import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import {formatDate } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-price-escalation',
  templateUrl: './price-escalation.component.html',
  styleUrls: ['./price-escalation.component.scss']
})
export class PriceEscalationComponent implements OnInit {

  searchText:string="";
  serchTerm: string = "";
  period: string = "";
  category: string = "";
  monthName: string = "";
  selectedZRMName: string = "";
  selectedRMName: string = "";
  selectedRegion: string = "";
  items = [];
  pageOfItems: Array<any>;  
  allPageOfItems:Array<any>;
  accountItem=[];
  accPageOfItem:Array<any>;  
  activeIdString:any="2";  
  sortText:string="";
  sortText2:string="";
  sortText3:string="";
  totCount:number=0;
  isList:boolean=true;
  customerItems=[];
  custPageOfItems:Array<any>;
  reverse: boolean = false;
  exportData:boolean=true;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService) { 
    this.period = this.activatedRoute.snapshot.params.period;

    if (this.period != "13")
        this.monthName = moment(this.period, 'M').format('MMMM');
      else
        this.monthName = "YTD";
  }

  ngOnInit(): void {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");

    this.getPriceEscalationList();
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  getPriceEscalationList() {
    debugger;
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.isList=false;
    this.customHttpService.getDataWithAuthentication("/Order/GetPriceEscalationList?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&period=" + this.period).subscribe(data => {
        debugger;
        console.log("PriceEscalationList",data);
        if (data.isSuccess) {
          console.log(data);
          this.items = data.responseData.detailModel;
          this.allPageOfItems=data.responseData.detailModel;
          this.customerItems=data.responseData.siteWiseModel; 
          this.custPageOfItems=this.customerItems;

          this.accountItem=data.responseData.parentAccountModel;
          this.accPageOfItem=this.accountItem;
          this.isList=true;
          this.totCount= this.items.reduce(function(prev,cur){
            return prev+cur.renewedOrderNumberCV;
          },0);
          
          this.commonSort(this.items,this.pageOfItems,"accountName",true);
          this.sortText="Account Name (Ascending)";  

          this.commonSort(this.customerItems, this.custPageOfItems,"accountName",true);   
          this.sortText2="Account Name (Ascending)";  

          this.commonSort(this.accountItem, this.accPageOfItem,"parentAccountName",true);  
          this.sortText3="Account Name (Ascending)"; 
          
         
        }
      });

  }

  roundNumber(no: any) {
    return Math.round(no);
  }
  
sortCol(column: string, isASc: boolean,listType:string, event, dataTyp?: any): void {
  //sortCol(column: string): void {
  console.log("sort" + isASc);

  var target = event.target;
  var parent = target.parentElement;
  var colName="";

  console.log(event.srcElement.nodeName);
  if (event.srcElement.nodeName == 'SPAN') {   
   var index=event.srcElement.innerHTML.indexOf( "<i" );
   colName=event.srcElement.innerHTML.substr(0,index);
   console.log(colName);
  }
  var sortedBy;
  if(isASc){
    sortedBy="Ascending";
  }
  else{
     sortedBy="Descending";
  }

  parent.parentElement.classList.remove("selectBackColor");
  parent.parentElement.childNodes.forEach(function (item) {
    item.classList.remove("selectBackColor");
  });

  parent.classList.add("selectBackColor");  

  if (listType == 'detail') {
    this.commonSort(this.items,this.pageOfItems,column,isASc,dataTyp);     
    this.sortText=colName+" ("+sortedBy+")";    
  }
  else if(listType=="siteWise") {
    this.commonSort(this.customerItems, this.custPageOfItems,column,isASc,dataTyp);    
    this.sortText2=colName+" ("+sortedBy+")";     
  }
  else
  {
    this.commonSort(this.accountItem, this.accPageOfItem,column,isASc,dataTyp); 
    this.sortText3=colName+" ("+sortedBy+")";   
  }
  
 
}
commonSort(listItems :any[],pageOfItem:Array<any>, column: string, isASc: boolean, dataTyp?: any){
  if (dataTyp != "date") {
    listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
    this.reverse = !this.reverse;
  }
  else {
    if (isASc == true) {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

    }
    else {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

    }
    this.reverse = !this.reverse
    listItems = pageOfItem;
  }
  this.onChangePage(listItems.slice(0, 20));
}

  search(value:string):void{
    this.isList=false;
   if(value.length>=3){
     //console.log(value);
     this.pageOfItems= this.allPageOfItems.filter((val)=>(val.regionName!=null && val.regionName.toLowerCase().includes(value.toLowerCase()))||     
     (val.scName!=null && val.scName.toLowerCase().includes(value.toLowerCase()))||
     (val.accountName!=null && val.accountName.toLowerCase().includes(value.toLowerCase()))||
     (val.accountNo !=null && val.accountNo.toLowerCase().includes(value.toLowerCase()))||
     (val.cvValue!=null && val.cvValue.toString().includes(value))||
     (val.renewedOrderNumberCV!=null && val.renewedOrderNumberCV.toString().includes(value))||
     (val.priceEscalationPercentage!=null && val.priceEscalationPercentage.toString().includes(value))||
     (val.orderNno!=null && val.orderNno.toLowerCase().includes(value.toLowerCase()))||
    //  (val.servicePlan!=null&& val.servicePlan.toLowerCase().includes(value.toLowerCase()))||
     (val.orderStartDateDisplayValue!=null && val.orderStartDateDisplayValue.toLowerCase().includes(value.toLowerCase()))||
     (val.orderEndDateDisplayValue!=null && val.orderEndDateDisplayValue.toLowerCase().includes(value.toLowerCase()))||
     (val.serviceGroup !=null && val.serviceGroup.toString().includes(value.toLowerCase())));    
   }
   else{
     this.pageOfItems= this.allPageOfItems;
   }
   this.items=this.pageOfItems;

   if(this.customerItems.length>0){
    if(value.length>=3){
     this.customerItems= this.custPageOfItems.filter(c=>(c.accountName!=null && c.accountName.toLowerCase().includes(value.toLowerCase()))||
     (c.accountNo !=null && c.accountNo.toLowerCase().includes(value.toLowerCase()))||    
     (c.regionName!=null && c.regionName.toLowerCase().includes(value.toLowerCase()))||
     (c.priceEscalationPercentage!=null && c.priceEscalationPercentage.toLowerCase().includes(value.toLowerCase()))||      
     (c.scName!=null && c.scName.toLowerCase().includes(value.toLowerCase()))||
      c.cvValue.toString().includes(value) || c.renewedOrderNumberCV.toString().includes(value));
    }
    else{
      this.customerItems= this.custPageOfItems;
    }
  }
  else {
    this.customerItems = this.custPageOfItems;
  }
  if (this.accountItem.length > 0) {
    if (value.length >= 3) {
      this.accountItem = this.accPageOfItem.filter(c => c.parentAccountNo.toLowerCase().includes(value.toLowerCase()) ||
        (c.parentAccountName != null && c.parentAccountName.toLowerCase().includes(value.toLowerCase())) ||
        (c.priceEscalationPercentage != null && c.priceEscalationPercentage.toLowerCase().includes(value.toLowerCase())) ||
        (c.svValue != null && c.svValue.toString().includes(value)) 
        || (c.renewedSVValue != null && c.renewedSVValue.toString().includes(value)));
    }
    else {
      this.accountItem = this.accPageOfItem;
    }
  }
  else {
    this.accountItem = this.accPageOfItem;
  }
  this.isList=true;
  //console.log(this.isList+" "+this.items.length);
  }

  setActiveIndex(evt: any) {
    console.log("set index"+evt.nextId);
    this.activeIdString = evt.nextId;
  }

  exportToExcel(){
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    var excelTyp;

    if (this.activeIdString == "1") {
      excelTyp = "Detail";
    }
    if (this.activeIdString == "2") {
      excelTyp = "SiteWise";
    }
    if (this.activeIdString == "3") {
      excelTyp = "AccWise";
    }

    console.log("excel" + excelTyp);
    this.exportData = false;

    this.customHttpService.exportDataWithAuthentication("/Order/ExportPriceEscalationList?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&category=" + this.category + "&period=" + this.period + "&excelType=" + excelTyp)
      .subscribe(data => {
        this.exportData = true;

        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);

        var nowDate = formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = excelTyp + "_PriceEscalation_" + this.period + "_" + nowDate + ".xls";
        anchor.href = url;
        anchor.click();
      });

  }

  checkLoading(){
    if(this.items!=undefined && this.items!=null && (this.items.length>0 || this.isList)&& this.exportData )
    {return true;
    }
    else
    {
      return false;
    }
    }

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}
