import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {

  segmentType:string="";
  period : string="";
  complaintStatus:string="";
  complaintList:any=[];
  selectedZRMName:string="";
  selectedRMName:string="";
  selectedRegion :string="";
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems:Array<any>;
  totCount:number=0;
  customerItems=[];
  custPageOfItems:Array<any>;
  searchText:string="";
  exportData:boolean=true;
  isList:boolean=true;
  sortText:string="";
  sortText2:string="";

  reverse: boolean = false;
  constructor(private httpClient: HttpClient, private activatedRoute:ActivatedRoute,private customHttpService:CustomhttpService) { 
  this.segmentType=  this.activatedRoute.snapshot.params.segmentType;
  this.period=  this.activatedRoute.snapshot.params.period;
  this.complaintStatus=  this.activatedRoute.snapshot.params.status;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}
  ngOnInit(): void {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
this.selectedRMName = localStorage.getItem("SelectedRMName");
this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.getComplaintList();
  }

  getComplaintList(){
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.isList=false;
this.customHttpService.getDataWithAuthentication("/Complaint/GetComplaintList?zrmId="+
zrmId+"&rmId="+rmId+"&region="+region+"&segmentType="+this.segmentType+"&period="+this.period+"&complaintStatus="+this.complaintStatus).subscribe(data=>{
  console.log("ght"+this.complaintStatus);
  if(data.isSuccess)
  {
    console.log(data);
    this.complaintList = data.responseData;
    this.items = data.responseData;
    this.allPageOfItems= data.responseData;
    this.totCount=this.items.length;
    this.customerItems=this.groupBy(this.complaintList);
    this.custPageOfItems=this.customerItems;
    this.isList=true;


    this.commonSort(this.items, this.pageOfItems,'accountName',true); 
    this.sortText="Account Name (Ascending)";   

    this.commonSort(this.customerItems, this.custPageOfItems,'accountName',true); 
    this.sortText2="Account Name (Ascending)";  
  }
});

}

checkLoading(){
if(this.items!=undefined && this.items!=null   && (this.items.length>0 || this.isList)&& this.exportData )
{return true;
}
else
{
  return false;
}
}
groupBy(array) {

  var distinctCustomerList = array.map(x=>x.accountId).filter((value, index, self) => self.indexOf(value) === index);
  let result = [];
  distinctCustomerList.forEach(function (a) {
    var individualCustomerList = [];
    individualCustomerList = array.filter(x=>x.accountId ==a); 
    result.push({
      regionName: individualCustomerList[0].regionName,
      scName: individualCustomerList[0].scName,
      accountName: individualCustomerList[0].accountName, 
      // cvValue: individualCustomerList.reduce(function(prev,cur) {
      //     return prev+cur.cvValue;
      //   },0),
      //accountId: individualCustomerList[0].accountNo,
      closedCount:individualCustomerList.filter(x=>x.status=='Closed').length,
      openCount:individualCustomerList.filter(x=>x.status=='Open'||x.status=='Reopened by Customer'||x.status=='Pending').length,
      resolvedCount:individualCustomerList.filter(x=>x.status=='Resolved').length
    });
  }, Object.create(null));
  console.log(result);
  return result;
  
  }
search(value:string):void{
  this.isList=false;
 if(value.length>=3){
   console.log(value);
   this.pageOfItems= this.allPageOfItems.filter(val=>(val.regionName!=null&& val.regionName.toLowerCase().includes(value.toLowerCase()))||
   val.createdDateDisplayValue.toLowerCase().includes(value.toLowerCase())||
   (val.scName!=null && val.scName.toLowerCase().includes(value.toLowerCase()))||
   (val.accountName !=null && val.accountName.toLowerCase().includes(value.toLowerCase()))||
   (val.technciainName!=null&& val.technciainName.toLowerCase().toString().includes(value.toLowerCase()))||
   (val.orderNumber!=null && val.orderNumber.toLowerCase().includes(value.toLowerCase()))||
  //(val.srName!=null&& val.srName.toLowerCase().includes(value.toLowerCase()))||
   (val.servicePlan!=null&&val.servicePlan.toLowerCase().includes(value.toLowerCase()))||
   (val.complaintDescription!=null && val.complaintDescription.toLowerCase().includes(value.toLowerCase()))||
   (val.status !=null && val.status.toLowerCase().includes(value.toLowerCase())));    
 }
 else{
   this.pageOfItems= this.allPageOfItems;
 }
 this.items=this.pageOfItems;

 //if(this.customerItems.length>0){
  if(value.length>=3){
   this.customerItems= this.custPageOfItems.filter(c=> (c.accountName !=null && c.accountName.toLowerCase().includes(value.toLowerCase()))||
    (c.regionName!=null && c.regionName.toLowerCase().includes(value.toLowerCase()))||    
    (c.scName !=null && c.scName.toLowerCase().includes(value.toLowerCase()))||
    (c.openCount!=null && c.openCount.toString().includes(value))||
    (c.closedCount!=null && c.closedCount.toString().includes(value))||
    (c.resolvedCount!=null && c.resolvedCount.toString().includes(value)));
  }
  else{
    this.customerItems= this.custPageOfItems;
  }
//}
this.isList=true;
}

sortCol(column: string, isASc: boolean,listType:string, event, dataTyp?: any): void {
  //sortCol(column: string): void {
  console.log("sort" + isASc);

  var target = event.target;
  var parent = target.parentElement;
  console.log(target);
  console.log(target.parentElement);
  parent.parentElement.classList.remove("selectBackColor");
  parent.parentElement.childNodes.forEach(function (item) {
    item.classList.remove("selectBackColor");
  });

  var colName="";
  if (event.srcElement.nodeName == 'SPAN') {   
    var index=event.srcElement.innerHTML.indexOf( "<i" );
    colName=event.srcElement.innerHTML.substr(0,index);
    console.log(colName);
   }
   var sortedBy;
  if(isASc){
    sortedBy="Ascending";
  }
  else{
     sortedBy="Descending";
  }

  parent.classList.add("selectBackColor");  
  
  if(listType=='detail'){
    this.commonSort(this.items, this.pageOfItems,column,isASc,dataTyp);  
    this.sortText=colName+" ("+sortedBy+")";   
  }
  else{

    this.commonSort(this.customerItems, this.custPageOfItems,column,isASc,dataTyp);
    this.sortText2=colName+" ("+sortedBy+")";   
  }

}

commonSort(listItems :any[],pageOfItem:Array<any>, column: string, isASc: boolean, dataTyp?: any){
  if (dataTyp != "date") {
    listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
    this.reverse = !this.reverse;
  }
  else {
    if (isASc == true) {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

    }
    else {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

    }
    this.reverse = !this.reverse
    listItems = pageOfItem;
  }
  this.onChangePage(listItems.slice(0, 20));
}

exportToExcel(){
  var zrmId = localStorage.getItem("SelectedZRMId");
  var rmId = localStorage.getItem("SelectedRMId");
  var region = localStorage.getItem("SelectedRegion");
  this.exportData=false;
  this.customHttpService.exportDataWithAuthentication("/Complaint/ExportComplaintList?zrmId="+
  zrmId+"&rmId="+rmId+"&region="+region+"&segmentType="+this.segmentType+"&period="+this.period+"&complaintStatus="+this.complaintStatus)
  .subscribe(data=>{
    this.exportData=true;
    let b: any = new Blob([data], {type: 'application/vnd.ms-excel' });
    var url = window.URL.createObjectURL(b);
    //window.open(url);

    var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
    var anchor = document.createElement("a");
    anchor.download = "Complaint_" +this.segmentType.replace('Accounts','Acc') +"_"+ this.period+"("+this.complaintStatus+")_"+nowDate+".xls";
    anchor.href = url;
    anchor.click();
  });
//   fetch("/Complaint/ExportComplaintList?zrmId="+
//    zrmId+"&rmId="+rmId+"&region="+region+"&segmentType="+this.segmentType+"&period="+this.period+"&complaintStatus="+this.complaintStatus, {
//     method: 'GET',
//     headers: {"Authorization":"bearer " + localStorage.getItem("gcbstoretoken")}
// })
//     .then(response => response.blob())
//     .then(blob => URL.createObjectURL(blob))
//     .then(url => {
//         var link = document.createElement("a");
//         link.setAttribute("href", url);
//         link.setAttribute("download", "test.csv");
//         link.style.display = "none";
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     });
}

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}