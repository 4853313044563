import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonMethods } from 'src/app/services/commonmethods';
import { formatDate } from '@angular/common';
import { NgbAccordionConfig,NgbDate,NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import{NgbDateCustomParserFormatter} from 'src/app/services/dateformat';

@Component({
  selector: 'app-tickets-interaction-detail',
  templateUrl: './tickets-interaction-detail.component.html',
  styleUrls: ['./tickets-interaction-detail.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class TicketsInteractionDetailComponent implements OnInit {

 
  selectedRegion: string = "";
  loggedinUserId: string = "";
  loggedInUserName: string = "";
  loggedinUserType: string = "";
  ticketId: string;
  interactionList: any;
  complaint: any;
  accountId: string;
  userResponse: string;
loggedinUserEmailid:string;
  randomColor=["green","#cc065f","#078da6","#bcb80f","#0f026a"];
  statusList=[];
  newStatus:string=null;
  isSave:boolean=false;
  lastVisitNgbDate:NgbDate;
  nextVisitNgbDate:NgbDate;
  nextVisitDate:NgbDate;
  pickerDate:NgbDate;
  selectedZRMName:string;
  selectedRMName:string;
  zrmId:string;
  rmId:string;
  pestCountList=[];
  viewInteraction: string = '';
  isViewInteraction: boolean = true;
  isInteractionFound: boolean = false;
  isInteractionLoad:boolean=true;

  constructor(private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService, private modalService: NgbModal, private commonMethods: CommonMethods) {
    this.accountId = this.activatedRoute.snapshot.params.accountId;
    this.ticketId = this.activatedRoute.snapshot.params.ticketId;
    var date = new Date();
    this.pickerDate=new NgbDate(date.getFullYear(),date.getMonth()+1, date.getDate());
    
  }

  ngOnInit() {   
    
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("loggedinuserid");
    this.loggedInUserName = localStorage.getItem("loggedinusername");
    this.loggedinUserEmailid = localStorage.getItem("loggedinemail");
    this.zrmId = localStorage.getItem("SelectedZRMId");
    this.rmId = localStorage.getItem("SelectedRMId");
    this.selectedRegion = localStorage.getItem("SelectedRegion");    
    this.loggedinUserType = localStorage.getItem("loggedinusertype");
   
    console.log( this.commonMethods.calculateDateDiff("2022-06-30T06:47:05Z"));
    this.getTicketStatus();
    this.getTicketsInteractionDetails();
     
    
  }

  getTicketsInteractionDetails() {

    this.customHttpService.getDataWithAuthentication("/Complaint/GetTicketComplaintDetailById?ticketId=" + this.ticketId)
      .subscribe(data => {
        console.log(data.responseData)
        //this.interactionList = data.responseData.interactionList;
        this.complaint = data.responseData;
      
      
        //this.lastVisitNgbDate= new NgbDate(new Date().getFullYear(),new Date().getMonth()+1,new Date().getDate()+1);
        // if(this.complaint.lastVisitDate!=undefined && this.complaint.lastVisitDate!=null && this.complaint.lastVisitDate!='')
        // {
        // var date = new Date(this.complaint.lastVisitDate);
        //    this.lastVisitNgbDate=new NgbDate(date.getFullYear(),date.getMonth()+1, date.getDate());
        // }
        // if(this.complaint.nextVisitDate!=undefined && this.complaint.nextVisitDate!=null && this.complaint.nextVisitDate!='')
        // {
        //    var date = new Date(this.complaint.nextVisitDate);
        //    this.nextVisitNgbDate=new NgbDate(date.getFullYear(),date.getMonth()+1, date.getDate());
        // }
        this.getPestDetailList();
      });

      
    //this.getInteractions();
   
    
    //commonService.getInteractionDetailsByTicket(this.ticketId)
    // this.interactionList = [{
    //   "body": "<div style='font - family:-apple - system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans - serif; font - size:14px'> <div>Hi Abhishek Singh,</div> <div dir='ltr'> <br>Complaint has been raised. </div> <div dir='ltr'>​<br> </div> <div dir='ltr'>@Vikas, please look into this. </div> <div dir='ltr'>​</div> <div dir='ltr'>Complaint number - 331894<br><br> </div> <div dir='ltr'><br></div> </div>",
    //   "from_email": "Hicare Enterprise Care < enterprise.care@hicare.in>", "created_at": "2022-06-29T03:34:38Z"
    // },
    // {
    //   "body": "Hi Team, We have done flushout tratmentment, This is preschedule service, We have found roaches in monitoring trap. No breeding point found. Regards, Vikas Singh Operation Manager 90821 49224 [New logo] HiCare Services Pvt. Ltd. 11/197, Bhirgu Path Mansarovar Jaipur 302020 www.hicare.in<http://www.hicare.in> ",
    //   "from_email": "vikas.s@hicare.in", "created_at": "2022-05-31T06:47:05Z"
    // }]
  }

getInteractions(){
  debugger;
  this.isInteractionLoad=false;
  this.interactionList=this.customHttpService.getDataForAbsoluteUrl(this.commonMethods.ticketDomain+ "/Ticket/GetTicketConversations?ticketId="+this.ticketId).subscribe(data=>{
    debugger;
    if ( data == null || data == undefined) {
      
      this.isInteractionFound = true;
      this.viewInteraction = "No Interactions Found!";
    }
    else if( data.length == 0){
      this.isInteractionFound = true;
      this.viewInteraction = "No Interactions Found!";
    }

    this.isInteractionLoad=true;
    if (data != undefined && data != null && data.length > 0) {
      this.isViewInteraction = false;
      this.interactionList = data;
      
      console.log(this.interactionList);
      for (let i = 0; i < this.interactionList.length; i++) {
      this.interactionList[i].timeDifference = this.commonMethods.calculateDateDiff(this.interactionList[i].created_at);
      this.interactionList[i].userFirstLetter=this.interactionList[i].from_email.substr(0,1);
      console.log(this.interactionList[i].timeDifferenc);
      this.interactionList[i].userIconColor=this.randomColor[Math.floor(Math.random() * 5)];

      var dateSent = new Date(this.interactionList[i].created_at);
      // this.interactionList[i].createdDisplayDate=dateSent.getDate()+" "+
      // this.commonMethods.monthNames[dateSent.getMonth()]+" "+dateSent.getFullYear()+" "+
      // dateSent.getHours()+" "+
      // dateSent.getMinutes()+" "+dateSent.getSeconds();
      var hours=dateSent.getHours();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;
      var minutes = dateSent.getMinutes();
      var minutesStr = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutesStr + ' ' + ampm;
    
      this.interactionList[i].createdDisplayDate=dateSent.getDate()+" "+
      this.commonMethods.monthNames[dateSent.getMonth()]+" "+
      dateSent.getFullYear()+" "+ strTime;
    }
    }
   
  });
  
}

getPestDetailList(){
  this.pestCountList=[];
  this.customHttpService.getDataWithAuthentication("/Complaint/GetPestDetailsByAccount?accountNo=" + this.complaint.accountId+"&lastRecordCnt=3")
  .subscribe(data => {
  
    console.log(data.responseData);
    this.pestCountList=data.responseData;
  })

}
submitResponse() {
  console.log(this.userResponse);
  var monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  if (this.newStatus != undefined && this.newStatus != null && this.newStatus != '') {
    var autoPrependText = "";
    if (this.userResponse != undefined && this.userResponse != null && this.userResponse != "") {
      autoPrependText = "<hr/>";
    }
    autoPrependText = autoPrependText + "\nStatus changed to: " + this.newStatus;
    var lvDate = this.lastVisitNgbDate != null ? (this.lastVisitNgbDate.day.toString() + "-" + monthNames[this.lastVisitNgbDate.month-1].toString() + "-" + (this.lastVisitNgbDate.year).toString()) : null;
    var nvDate = this.nextVisitNgbDate != null ? (this.nextVisitNgbDate.day.toString() + "-" + monthNames[this.nextVisitNgbDate.month-1].toString() + "-" + (this.nextVisitNgbDate.year).toString()) : null;
    if (lvDate != undefined && lvDate != null && lvDate != "") {
      autoPrependText = autoPrependText + ", Last Visit Date: " + (lvDate==null || lvDate=="null"?"":lvDate);
    }
    if (lvDate != undefined && lvDate != null && lvDate != "") {
      autoPrependText = autoPrependText + ", Next Visit Date: " + (nvDate==null || nvDate=="null"?"":nvDate);
    }
    if (this.userResponse != undefined && this.userResponse != null && this.userResponse != "") {
      this.userResponse = this.userResponse + autoPrependText;
    }
    else {
      this.userResponse = autoPrependText;
    }
  }
  if (this.userResponse != undefined && this.userResponse != null && this.userResponse != "") {
    
    this.userResponse = this.userResponse + ", updated by: "+this.loggedInUserName + " | " + this.loggedinUserEmailid; 
    var dataToPost = this.userResponse.split("\n").join("<br/>");
    var attachments = [];
    console.log("inside int call")

    console.log(dataToPost);

    this.customHttpService.postDataWithoutAuthenticationForAbsoluteURL(this.commonMethods.ticketDomain + "/Ticket/AddTicketComment",
      { "TicketId": this.ticketId, "Message": dataToPost, "CustomerEmail": this.loggedinUserEmailid, "Attachments": attachments })
      .subscribe(data => {
        this.isSave = true;
        setTimeout(() => { this.isSave = false; }, 5000);
        this.userResponse="";
        //this.getInteractions();
      });
  }

  //submit status
  if (this.newStatus != undefined && this.newStatus != null && this.newStatus != '') {
    this.changeStatus();
  }
}

changeStatus() {
  var statusToBeSentToFD = this.newStatus.replace("-", "");
  statusToBeSentToFD = statusToBeSentToFD.replace("/","");
  statusToBeSentToFD = statusToBeSentToFD.replace("-","");
  statusToBeSentToFD = statusToBeSentToFD.replace(/ /g, '');
  console.log(this.newStatus);
  console.log(statusToBeSentToFD);
  console.log(this.lastVisitNgbDate);
  console.log(this.nextVisitNgbDate);
  this.customHttpService.getDataForAbsoluteUrl(this.commonMethods.ticketDomain + "/Ticket/ChangeStatus?ticketId=" + this.complaint.fdTicketId + "&status=" + statusToBeSentToFD)
    .subscribe(data => {
      var postData = {};
      var lvDate = this.lastVisitNgbDate != null ? (this.lastVisitNgbDate.year.toString() + "-" + (this.lastVisitNgbDate.month).toString() + "-" + (this.lastVisitNgbDate.day).toString()) : null;
      var nvDate = this.nextVisitNgbDate != null ? (this.nextVisitNgbDate.year.toString() + "-" + (this.nextVisitNgbDate.month).toString() + "-" + (this.nextVisitNgbDate.day).toString()) : null;
      postData = { "OldStatus": this.complaint.status, "Status": this.newStatus, "TicketId": this.complaint.fdTicketId, "LastVisitDate": lvDate, "NextVisitDate": nvDate };
        this.customHttpService.postData("/Complaint/ChangeComplaintStatus", postData
        ).subscribe(data => {
          this.isSave = true;
          this.lastVisitNgbDate = null;
          this.nextVisitNgbDate = null;
          this.newStatus = null;
          this.userResponse = "";
          this.getTicketsInteractionDetails();
          setTimeout(() => { this.isSave = false; }, 5000);
        });      
    });
}

  checkStatus(status){
    // if(status=='Closed' ||status=='Resolved')
    // return false;
    // else
    // return true;
    return true;
  }

  onDateSelect(event) {
    
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate =day+ "-" + month + "-" + year;
    return finalDate;
   }

   getTicketStatus(){
    
    this.customHttpService.getDataWithAuthentication("/Complaint/GetComplaintStatusMasterList?userTypeName=" + this.loggedinUserType)
      .subscribe(data => {
        console.log(data.responseData);

        this.statusList=data.responseData;

      })
  }

  navigateToAccountDetail(accountId:string){
    var accountNo= accountId.split('/')[0];
    var url = "tickets_detail/"+accountNo;
    window.open(url, "_blank");
  }
}
