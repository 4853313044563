import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { DecimalPipe } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { LoginComponent } from './login/login.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { CustomhttpService } from './services/customhttp.service';
import { ComplaintComponent } from './data-tables/complaint/complaint.component';
import { SrbaseComponent } from './data-tables/srbase/srbase.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { HttpErrorInterceptor } from './services/http-interceptor';
import { RedaccountComponent } from './data-tables/redaccount/redaccount.component';
import { InvoicesComponent } from './data-tables/invoices/invoices.component';
import { RbtDeliveryComponent } from './data-tables/rbt-delivery/rbt-delivery.component';
import { FarmingOrderComponent } from './data-tables/farming-order/farming-order.component';
import { RenewalOrderComponent } from './data-tables/renewal-order/renewal-order.component';
import { AccountSummaryComponent } from './data-tables/account-summary/account-summary.component';
import {NgbDateCustomParserFormatter} from './services/dateformat';
import { TicketsComponent } from './data-tables/tickets/tickets.component';
import { TicketsDetailComponent } from './data-tables/tickets-detail/tickets-detail.component';
import { TicketsInteractionDetailComponent } from './data-tables/tickets-interaction-detail/tickets-interaction-detail.component';
import {CommonMethods} from './services/commonmethods';
import { UploadFileComponent } from './data-tables/upload-file/upload-file.component';
import { DocumentUploaderComponent } from './data-tables/document-uploader/document-uploader.component';
import { DocumentMappingComponent } from './data-tables/document-mapping/document-mapping.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { S3loginComponent } from './s3login/s3login.component';

import { RMMappingComponent } from './rm-mapping/rm-mapping.component';
import { PriceEscalationComponent } from './data-tables/price-escalation/price-escalation.component';
import { ProfitabilityComponent } from './data-tables/profitability/profitability.component';
import { OnBoardingRMComponent } from './data-tables/onboarding-rm/onboarding-rm.component';
import { GoogleAuthComponent } from './googleAuthLogin/google-auth/google-auth.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    SpinnerComponent,
    ContentAnimateDirective,
    LoginComponent,
    ComplaintComponent,
    SrbaseComponent,
    RedaccountComponent,
    InvoicesComponent,
    RbtDeliveryComponent,
    FarmingOrderComponent,
    RenewalOrderComponent,
    AccountSummaryComponent,
    TicketsComponent,
    TicketsDetailComponent,
    TicketsInteractionDetailComponent,
    UploadFileComponent,
    DocumentUploaderComponent,
    DocumentMappingComponent,
    S3loginComponent,    
    RMMappingComponent,
    PriceEscalationComponent,
    ProfitabilityComponent,
    OnBoardingRMComponent,
    GoogleAuthComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    JwPaginationModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [ThemeService,CustomhttpService,{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },NgbDateCustomParserFormatter,CommonMethods,DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
