import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { CommonMethods } from 'src/app/services/commonmethods';
import { formatDate } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';

@Component({
  selector: 'app-renewal-order',
  templateUrl: './renewal-order.component.html',
  styleUrls: ['./renewal-order.component.scss']
})
export class RenewalOrderComponent implements OnInit {
  searchText: string = "";
  category: string = "";
  period: string = "";
  monthName: string = "";
  renewAccountList: any = [];
  siteAccountList: any = [];
  selectedZRMName: string = "";
  selectedRMName: string = "";
  selectedRegion: string = "";
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems: Array<any>;
  totVal: number = 0;
  reverse: boolean = false;
  exportData: boolean = true;
  isList: boolean = true;
  loggedinUserId: string = "";
  customerItems = [];
  custPageOfItems: Array<any>;
  sitePageOfItems: Array<any>;
  activeIdString: any = "2";
  isSave: boolean = false;
  sortText: string = "";
  sortText2: string = "";
  sortText3: string = "";
  rrmRemarkList = [];
  isnrmremarkdisabled: boolean = true;
  dropdownSettings: IDropdownSettings;
  selectedRemarks: any;
  searchedList = [];
  searchValue: string;
  quoteSubmittedList = [];
  isRemarkSummaryList: boolean = false;
  renewalType: string = "";
  isAllSave: boolean = false;
  newData: any[] = [];
  year: string = "";

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private commonMethods: CommonMethods) {
    debugger;
    this.category = this.activatedRoute.snapshot.params.category;
    this.period = this.activatedRoute.snapshot.params.period;
    this.year = this.activatedRoute.snapshot.params.year;
    this.renewalType = this.activatedRoute.snapshot.params.renewalType;

    if (this.renewalType == "RenewalPerformanceBase" || this.renewalType == "RemarkBasedRenewal") {
      if (this.period != "13")
        this.monthName = moment(this.period, 'M').format('MMMM');
      else
        this.monthName = "YTD";
      console.log("date" + moment(this.period, 'M').format('MMMM'));
    }
  }
  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length > 0 || this.isList) && this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }
  roundNumber(no: any) {
    return Math.round(no); //parseFloat(no).toFixed(2);
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit(): void {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("loggedinuserid");
    if (localStorage.getItem("loggedinemail") == "dipak.malkar@hicare.in") {
      this.isnrmremarkdisabled = false;
    }
    else {
      this.isnrmremarkdisabled = true;
    }
    this.getRenewAccountList();

    this.rrmRemarkList = this.commonMethods.rrmRemarks;
    this.dropdownSettings = {
      singleSelection: false,
      itemsShowLimit: 2
    }
    this.selectedRemarks = this.rrmRemarkList;
    this.quoteSubmittedList = ["Yes", "No"];
    //this.checkIsRemarkSummaryList();
  }

  checkIsRemarkSummaryList() {
    if (this.category == 'SV' || this.category == 'PendingForRenewal' || this.category=='PendingForRenewalPerformanceBase' || this.category == 'BookedSV' || this.category == 'ClaimedSV' || this.category == 'LostSV' || this.category == 'SingleOrderSV') {
      return true;
    }
    else {
      return false;
    }
  }
  getRenewAccountList() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.isList = false;
    this.customHttpService.getDataWithAuthentication("/Order/GetRenewalOrderList?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&category=" + this.category + "&period=" + this.period + "&year=" + this.year).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          console.log(this.category)
          this.renewAccountList = data.responseData.detailModel;
          this.items = data.responseData.detailModel;
          this.allPageOfItems = data.responseData.detailModel;

          this.siteAccountList = data.responseData.siteWiseModel;
          this.sitePageOfItems = data.responseData.siteWiseModel;

          this.customerItems = data.responseData.parentAccountModel;//this.groupBy(data.responseData.parentAccountModel).sort((a, b) => this.compareObjects(a, b, 'parentAccountName'));
          this.custPageOfItems = this.customerItems;

          this.isList = true;

          if (this.category == "SV" || this.category == "PendingForRenewal" || this.category == "PendingForRenewalPerformanceBase" || this.category == 'Lost' || this.category == 'LostSV' || this.category == 'RedAccount' || this.category == 'Renewed' || this.category == 'QuotationSent' || this.category == 'QuotationPending' || this.category == 'NegotiationInProgress' || this.category == 'ClaimedSV' || this.category == 'BookedSV' || this.category == 'SingleOrder' || this.category == 'InHand' || this.category == 'SingleOrderSV') {
            this.totVal = this.items.reduce(function (prev, cur) {
              return prev + cur.cvValue;
            }, 0);
          }

          if (this.category == "Customer") {
            this.totVal = this.items.length;
          }

          this.commonSort(this.items, this.pageOfItems, 'accountName', true);
          this.sortText = "Account Name (Ascending)";

          this.commonSort(this.siteAccountList, this.sitePageOfItems, 'accountName', true);
          this.sortText2 = "Account Name (Ascending)";

          this.commonSort(this.customerItems, this.custPageOfItems, 'parentAccountName', true);
          this.sortText3 = "Account Name (Ascending)";

        }
      });

  }

  compareObjects(object1, object2, key) {

    if (object1[key] != null && object2[key] != null) {
      const obj1 = object1[key].toUpperCase()
      const obj2 = object2[key].toUpperCase()

      if (obj1 < obj2) {
        return -1
      }
      if (obj1 > obj2) {
        return 1
      }
      return 0
    }
  }

  groupBy(array) {

    var distinctCustomerList = array.map(x => x.parentAccountNo).filter((value, index, self) => self.indexOf(value) === index);
    console.warn("list", distinctCustomerList);
    let result = [];
    distinctCustomerList.forEach(function (a) {
      var individualCustomerList = [];
      individualCustomerList = array.filter(x => x.parentAccountNo == a);
      result.push({
        parentAccountName: individualCustomerList[0].parentAccountName,
        rrmRemark: individualCustomerList[0].rrmRemark,
        zrmRemark: individualCustomerList[0].zrmRemark,
        cvValue: individualCustomerList.reduce(function (prev, cur) {
          return prev + cur.cvValue;
        }, 0),
        parentAccountNo: individualCustomerList[0].parentAccountNo
      });
    }, Object.create(null));

    console.log(result);
    return result;

  }

  search(value: string): void {
    this.isList = false;
    debugger;
    this.searchValue = value;
    if (value.length >= 3) {
      console.log(value);
      if (this.category == 'SV' || this.category == "PendingForRenewal" || this.category == "PendingForRenewalPerformanceBase") {
        this.pageOfItems = this.allPageOfItems.filter((val) => (val.regionName != null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
          (val.scName != null && val.scName.toLowerCase().includes(value.toLowerCase())) ||
          (val.accountNo != null && val.accountNo.toLowerCase().includes(value.toLowerCase())) ||
          (val.rrmName != null && val.rrmName.toLowerCase().includes(value.toLowerCase())) ||
          (val.accountName != null && val.accountName.toLowerCase().includes(value.toLowerCase())) ||
          (val.cvValue != null && val.cvValue.toString().includes(value)) ||
          (val.orderNno != null && val.orderNno.toLowerCase().includes(value.toLowerCase())) ||
          //  (val.servicePlan!=null && val.servicePlan.toLowerCase().includes(value.toLowerCase()))||
          (val.serviceGroup != null && val.serviceGroup.toLowerCase().includes(value.toLowerCase())) ||
          (val.orderStartDateDisplayValue != null && val.orderStartDateDisplayValue.toString().includes(value.toLowerCase())) ||
          (val.orderEndDateDisplayValue != null && val.orderEndDateDisplayValue.toString().includes(value.toLowerCase())));
      }
      else {
        this.pageOfItems = this.allPageOfItems.filter(val => val.accountName.toLowerCase().includes(value.toLowerCase()) ||
          val.accountNo.toLowerCase().includes(value.toLowerCase()) ||
          val.cvValue.toString().includes(value) ||
          val.regionName.toLowerCase().includes(value.toLowerCase()) ||
          val.scName.toLowerCase().includes(value.toLowerCase()));
      }
    }

    else {
      this.pageOfItems = this.allPageOfItems;
    }
    this.items = this.pageOfItems;

    if (this.customerItems.length > 0) {
      if (value.length >= 3) {
        this.customerItems = this.custPageOfItems.filter(c => c.parentAccountName != null && c.parentAccountName.toLowerCase().includes(value.toLowerCase()) ||

          (c.parentAccountNo != null && c.parentAccountNo.toLowerCase().includes(value.toLowerCase())) ||
          // (c.rrmRemark != null && c.rrmRemark.toLowerCase().includes(value.toLowerCase())) ||
          // (c.zrmRemark != null && c.zrmRemark.toLowerCase().includes(value.toLowerCase())) ||
          (c.cvValue != null && c.cvValue.toString().includes(value)));
      }
      else {
        this.customerItems = this.custPageOfItems;
      }
    }
    else {
      this.customerItems = this.custPageOfItems;
    }
    debugger;
    if (this.siteAccountList.length > 0 || this.selectedRemarks.length > 0) {
      if (value.length >= 3) {
        this.siteAccountList = this.sitePageOfItems.filter(val => val.accountName.toLowerCase().includes(value.toLowerCase()) ||
          val.accountNo.toLowerCase().includes(value.toLowerCase()) ||
          val.cvValue.toString().includes(value) ||
          (val.regionName != null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
          (val.zrmRemark != null && val.zrmRemark.toLowerCase().includes(value.toLowerCase())) ||
          (val.rrmName != null && val.rrmName.toLowerCase().includes(value.toLowerCase())) ||
          (val.rrmRemark != null && val.rrmRemark.toLowerCase().includes(value.toLowerCase())) ||
          (val.nrmRemark != null && val.nrmRemark.toLowerCase().includes(value.toLowerCase()))
          || (val.scName.toLowerCase().includes(value.toLocaleLowerCase()))
        );
      }
      else {
        debugger;
        this.siteAccountList = this.sitePageOfItems;
      }
    }
    else {
      debugger;
      this.siteAccountList = this.sitePageOfItems;
    }
    this.searchedList = this.siteAccountList;
    this.isList = true;
    this.filterByRRMRemark();
  }

  sortCol(column: string, isASc: boolean, listType: string, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    var colName = "";

    console.log(event.srcElement.nodeName);
    if (event.srcElement.nodeName == 'SPAN') {
      var index = event.srcElement.innerHTML.indexOf("<i");
      colName = event.srcElement.innerHTML.substr(0, index);
      console.log(colName);
    }
    var sortedBy;
    if (isASc) {
      sortedBy = "Ascending";
    }
    else {
      sortedBy = "Descending";
    }
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    if (listType == 'detail') {
      this.commonSort(this.items, this.pageOfItems, column, isASc, dataTyp);
      this.sortText = colName + " (" + sortedBy + ")";
    }
    else if (listType == 'sitewise') {
      this.commonSort(this.siteAccountList, this.sitePageOfItems, column, isASc, dataTyp);
      this.sortText2 = colName + " (" + sortedBy + ")";

    }
    else {
      this.commonSort(this.customerItems, this.custPageOfItems, column, isASc, dataTyp);
      this.sortText3 = colName + " (" + sortedBy + ")";

      //this.onChangePage(this.customerItems.slice(0, 20));
    }
  }
  commonSort(listItems: any[], pageOfItem: Array<any>, column: string, isASc: boolean, dataTyp?: any) {
    if (dataTyp != "date") {
      listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
      this.reverse = !this.reverse;
    }
    else {
      if (isASc == true) {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

      }
      else {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

      }
      this.reverse = !this.reverse
      listItems = pageOfItem;
    }
    this.onChangePage(listItems.slice(0, 20));
  }
  setActiveIndex(evt: any) {
    console.log("set index" + evt.nextId);
    this.activeIdString = evt.nextId;
  }

  saveRemark(item) {
    debugger;
    this.customHttpService.postData("/order/UpdateRenewalOrderRemark",
      {
        "AccountNo": item.accountNo,
        "ZRMRemark": item.zrmRemark,
        "RRMRemark": item.rrmRemark,
        "UserId": this.loggedinUserId,
        "OrderType": "Renewal",
        "OrderNo": item.orderNo,
        "NRMRemark": item.nrmRemark,
        "QuoteSubmitted": item.quoteSubmittedRemark,
        "PriceEscalation": item.priceEscalationRemark
      }).subscribe(data => {
        if (data.isSuccess) {
          this.isSave = true;
          setTimeout(() => { this.isSave = false; }, 5000);
        }
      });
  }

  onDescriptionChange(item: any){
    debugger;
    item.isChange = true;
  }

  saveAllRemark(){
    debugger;
    this.isAllSave = true;
    this.newData = this.siteAccountList.filter(a => a.isChange == true).map((item)=> (
      {
        "AccountNo": item.accountNo,
        "ZRMRemark": item.zrmRemark,
        "RRMRemark": item.rrmRemark,
        "UserId": this.loggedinUserId,
        "OrderType": "Renewal",
        "OrderNo": item.orderNo,
        "NRMRemark": item.nrmRemark,
        "QuoteSubmitted": item.quoteSubmittedRemark,
        "PriceEscalation": item.priceEscalationRemark
      }
    ) );
    //console.log("UpdatedData", this.newData);

    if(this.newData != null && this.newData.length > 0){
      debugger
      this.customHttpService.postData("/order/SaveAllRenewalBaseRemark", this.newData).subscribe(data => {
        if (data.isSuccess) {
          alert("All Remark Saved!");
          console.log(data);
          this.isAllSave = true;
          setTimeout(() => { this.isAllSave = false; }, 5000);
        }
        else{
          alert("Something went wrong!");
        }
      });
    }
    else{
      this.isAllSave = false;
      alert("No Data Updated.");
    }
  }
  exportToExcel() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    var excelTyp;

    if (this.activeIdString == "1") {
      excelTyp = "Detail";
    }
    if (this.activeIdString == "2") {
      excelTyp = "SiteWise";
    }
    if (this.activeIdString == "3") {
      excelTyp = "AccWise";
    }

    console.log("excel" + excelTyp);
    this.exportData = false;

    this.customHttpService.exportDataWithAuthentication("/Order/ExportRenewAccountList?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&category=" + this.category + "&period=" + this.period + "&excelType=" + excelTyp + "&year=" + this.year)
      .subscribe(data => {
        this.exportData = true;

        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);

        var nowDate = formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = excelTyp + "_Renewal_" + this.period + "_" + nowDate + ".xls";
        anchor.href = url;
        anchor.click();
      });

  }

  onRRMRemarkSelection(event: any, checked: boolean) {
    console.log(this.selectedRemarks);
    if ((this.selectedRemarks.length > 0 && this.searchValue != '')
      && (this.selectedRemarks.length > 0 && this.searchValue != undefined)
      && (this.selectedRemarks.length > 0 && this.searchValue != null)) {
      debugger;
      var selectedRemarksStr = this.selectedRemarks.join();
      this.siteAccountList = this.sitePageOfItems.filter(val => val.rrmRemark != null
        && selectedRemarksStr.toLowerCase().includes(val.rrmRemark.toLowerCase()));

      this.search(this.searchValue);
    }
    else if ((this.selectedRemarks.length > 0 && this.searchValue == '')
      || (this.selectedRemarks.length > 0 && this.searchValue == undefined)
      || (this.selectedRemarks.length > 0 && this.searchValue == null)) {
      debugger;
      var selectedRemarksStr = this.selectedRemarks.join();
      this.siteAccountList = this.sitePageOfItems.filter(val => val.rrmRemark != null
        && selectedRemarksStr.toLowerCase().includes(val.rrmRemark.toLowerCase()));
    }
    else if (this.selectedRemarks.length == 0 || this.selectedRemarks == undefined) {
      this.siteAccountList = [];
    }
    else {
      this.siteAccountList = this.sitePageOfItems;
    }
    this.isList = true;
  }

  onAllRRMRemarkSelection(event: any, checked: boolean) {
    this.selectedRemarks = event;
    if (checked == true) {
      this.siteAccountList = this.sitePageOfItems;
      this.isList = true;
      this.search(this.searchValue);
    }
    else {
      this.siteAccountList = [];
    }
  }

  filterByRRMRemark() {
    debugger;
    console.log(this.selectedRemarks);
    if (this.selectedRemarks.length > 0 && this.selectedRemarks != undefined) {
      debugger;
      var selectedRemarksStr = this.selectedRemarks.join();
      this.siteAccountList = this.searchedList.filter(val =>
       selectedRemarksStr.toLowerCase().includes(val.rrmRemark.toLowerCase()));
    }
    else if ((this.searchedList.length == 0 && this.selectedRemarks.length == 0)
      || (this.searchedList.length == 0 && this.selectedRemarks == undefined)
      || (this.searchedList.length > 0 && this.selectedRemarks.length == 0)
      || (this.searchedList.length > 0 && this.selectedRemarks == undefined)) {
      this.siteAccountList = [];
    }
    else {
      this.siteAccountList = this.sitePageOfItems;
    }
    this.isList = true;
  }

}


function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}
