import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-redaccount',
  templateUrl: './redaccount.component.html',
  styleUrls: ['./redaccount.component.scss']
})
export class RedaccountComponent implements OnInit {

  category:string="";
  period : string="";
  redAccountList:any=[];
  selectedZRMName:string="";
  selectedRMName:string="";
  selectedRegion :string="";
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems:Array<any>;
  totVal:number=0;
  searchText:string="";
  reverse:boolean=false;
  exportData:boolean=true;
  isList:boolean=true;
  siteItems=[];
  sitePageOfItems:Array<any>;

  accountItem=[];
  accountPageOfItem:Array<any>;
  activeIdString:any="2";  
  sortText:string="";
  sortText2:string="";
  sortText3:string="";

  constructor(private httpClient: HttpClient, private activatedRoute:ActivatedRoute,private customHttpService:CustomhttpService) { 
  this.category=  this.activatedRoute.snapshot.params.category;
  this.period=  this.activatedRoute.snapshot.params.period;
  }
  roundNumber(no:any){
    return Math.round(no);
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}
  ngOnInit(): void {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.getRedAccountList();
  }
  checkLoading(){
    if(this.items!=undefined && this.items!=null  && (this.items.length>0 || this.isList)&& this.exportData )
    {return true;
    }
    else
    {
      return false;
    }
    }
  getRedAccountList(){
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.isList=false;
this.customHttpService.getDataWithAuthentication("/Order/GetRedAccountList?zrmId="+
zrmId+"&rmId="+rmId+"&region="+region+"&category="+this.category+"&period="+this.period).subscribe(data=>{
  console.log(data);
  if(data.isSuccess)
  {
    console.log(data);
    this.redAccountList = data.responseData.detailModel;
    this.items = data.responseData.detailModel;
    this.allPageOfItems=data.responseData.detailModel;

    this.siteItems = data.responseData.siteWiseModel; //this.groupBy(this.srList).sort((a, b) => this.compareObjects(a, b, 'accountName') || this.compareObjects(a, b, 'accountId'));
    this.sitePageOfItems = this.siteItems;

    this.accountItem=data.responseData.parentWiseModel;
    this.accountPageOfItem=data.responseData.parentWiseModel;

    this.isList=true;
   
      this.commonSort(this.items,this.pageOfItems,"accountName",true);   
      this.sortText="Account Name (Ascending)";   
    
    
      this.commonSort(this.siteItems, this.sitePageOfItems,"accountName",true);
      this.sortText2="Account Name (Ascending)";    
  
    
      this.commonSort(this.accountItem, this.accountPageOfItem,"parentAccountName",true);
      this.sortText3="Account Name (Ascending)"; 

    if(this.category=='SV'){
    this.totVal= this.items.reduce(function(prev,cur){
      return prev+cur.cvValue;
    },0);
  }
  else{
    this.totVal=this.items.length;
  }
  }
});

}

search(value:string):void{
  this.isList=false;
  if(value.length>=3){
    if(this.category=='SV'){
    this.pageOfItems= this.allPageOfItems.filter((val)=>(val.regionName!=null && val.regionName.toLowerCase().includes(value.toLowerCase()))||     
    (val.scName !=null && val.scName.toLowerCase().includes(value.toLowerCase()))||
    (val.accountName!=null && val.accountName.toLowerCase().includes(value.toLowerCase()))||
    (val.accountNo!=null && val.accountNo.toLowerCase().includes(value.toLowerCase()))||
    (val.cvValue!=null && val.cvValue.toString().includes(value))||
    (val.orderNno!=null && val.orderNno.toLowerCase().includes(value.toLowerCase()))||
    (val.serviceGroup!=null&& val.serviceGroup.toLowerCase().includes(value.toLowerCase()))||
    (val.orderStartDateDisplayValue!=null && val.orderStartDateDisplayValue.toLowerCase().includes(value.toLowerCase()))||
    (val.orderEndDateDisplayValue!=null && val.orderEndDateDisplayValue.toLowerCase().includes(value.toLowerCase())));    
  }
  else{
    this.pageOfItems= this.allPageOfItems.filter((val)=>val.regionName.toLowerCase().includes(value.toLowerCase())||     
    val.accountName.toLowerCase().includes(value.toLowerCase())||
    val.accountNo.toLowerCase().includes(value.toLowerCase())||
    val.scName.toLowerCase().includes(value.toLowerCase())||
    val.cvValue.toString().includes(value));    
  }
}
  else{
    this.pageOfItems= this.allPageOfItems;
  }
  this.items=this.pageOfItems;

  if (this.siteItems.length > 0) {
    if (value.length >= 3) {
      this.siteItems = this.sitePageOfItems.filter(c => c.accountName.toLowerCase().includes(value.toLowerCase()) ||
        (c.accountNo != null && c.accountNo.toLowerCase().includes(value.toLowerCase())) ||
        (c.regionName != null && c.regionName.toLowerCase().includes(value.toLowerCase())) ||
        (c.scName != null && c.scName.toLowerCase().includes(value.toLowerCase())) ||
        (c.cvValue != null && c.cvValue.toString().includes(value)) );
        // (c.closedSV != null && c.closedSV.toString().includes(value)));
    }
    else {
      this.siteItems = this.sitePageOfItems;
    }
  }
  else {
    this.siteItems = this.sitePageOfItems;
  }

  if (this.accountItem.length > 0) {
    if (value.length >= 3) {
      this.accountItem = this.accountPageOfItem.filter(c => c.parentAccountNo.toLowerCase().includes(value.toLowerCase()) ||
        (c.parentAccountName != null && c.parentAccountName.toLowerCase().includes(value.toLowerCase())) ||
        (c.svValue != null && c.svValue.toString().includes(value)) );
    }
    else {
      this.accountItem = this.accountPageOfItem;
    }
  }
  else {
    this.accountItem = this.accountPageOfItem;
  }
  this.isList=true;
 }

 sortCol(column: string, isASc: boolean, listType: string, event, dataTyp?: any): void {
  //sortCol(column: string): void {
  console.log("sort" + isASc);

  var target = event.target;
  var parent = target.parentElement;
  var colName="";

  console.log(event.srcElement.nodeName);
  if (event.srcElement.nodeName == 'SPAN') {   
   var index=event.srcElement.innerHTML.indexOf( "<i" );
   colName=event.srcElement.innerHTML.substr(0,index);
   console.log(colName);
  }
   var sortedBy;
   if (isASc) {
     sortedBy = "Ascending";
   }
   else {
     sortedBy = "Descending";
   }
  parent.parentElement.classList.remove("selectBackColor");
  parent.parentElement.childNodes.forEach(function (item) {
    item.classList.remove("selectBackColor");
  });

  
  

  parent.classList.add("selectBackColor");

  if (listType == 'detail') {
    this.commonSort(this.items,this.pageOfItems,column,isASc,dataTyp);   
    this.sortText=colName+" ("+sortedBy+")";   
  }
  else if(listType=="sitewise") {
    this.commonSort(this.siteItems, this.sitePageOfItems,column,isASc,dataTyp);
    this.sortText2=colName+" ("+sortedBy+")";      
  }
  else
  {
    this.commonSort(this.accountItem, this.accountPageOfItem,column,isASc,dataTyp); 
    this.sortText3=colName+" ("+sortedBy+")";
  }

}

commonSort(listItems :any[],pageOfItem:Array<any>, column: string, isASc: boolean, dataTyp?: any){
  if (dataTyp != "date") {
    listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
    this.reverse = !this.reverse;
  }
  else {
    if (isASc == true) {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

    }
    else {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

    }
    this.reverse = !this.reverse
    listItems = pageOfItem;
  }
  this.onChangePage(listItems.slice(0, 20));
}
setActiveIndex(evt: any) {
  console.log("set index"+evt.nextId);
  this.activeIdString = evt.nextId;
}

exportToExcel(){
  var zrmId = localStorage.getItem("SelectedZRMId");
  var rmId = localStorage.getItem("SelectedRMId");
  var region = localStorage.getItem("SelectedRegion");

this.exportData=false;
var excelTyp;
console.log(this.activeIdString);
   
if(this.activeIdString=="1"){
  excelTyp="Detail";
}
if(this.activeIdString=="2"){
 excelTyp="SiteWise";
}
if(this.activeIdString=="3"){
  excelTyp="AccountWise";
}
  this.customHttpService.exportDataWithAuthentication("/Order/ExportRedAccountList?zrmId="+
  zrmId+"&rmId="+rmId+"&region="+region+"&category="+this.category+"&period="+this.period+"&excelTyp="+excelTyp)
  .subscribe(data=>{
    this.exportData=true;

    let b: any = new Blob([data], {type: 'application/vnd.ms-excel' });
    var url = window.URL.createObjectURL(b);
    //window.open(url);
    var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
    var anchor = document.createElement("a");
    anchor.download =excelTyp+ "_RedAccount_" +this.period+"_"+nowDate+".xls";
    anchor.href = url;
    anchor.click();
  });

}

}


function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}
