import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss']
})
export class AccountSummaryComponent implements OnInit {

  items = [];
  exportData:boolean=true;
  isList:boolean=true;
  constructor() { }

  ngOnInit() {
  }

  checkLoading(){
    if(this.items!=undefined && this.items!=null  && (this.items.length>0 || this.isList)&& this.exportData )
    {return true;
    }
    else
    {
      return false;
    }
    }
}
