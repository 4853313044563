import { Injectable } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';

@Injectable()
export class CommonMethods {

  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  rrmRemarks=["Lost","Red Account","Renewed","Quotation Sent","Quotation Pending","Negotiation in progress","Single Order", "In-Hand"];

  invoiceRemarks = ["Collectable","Collected", "Credit Note", "Service Issues", "Legal Case", "Invoice Correction"];

  ticketDomain = "https://connect.hicare.in/Freshdesk/api";

  commonSort(listItems: any[], pageOfItem: Array<any>, isReverse: boolean, event, column: string, isASc: boolean, dataTyp?: any): SortModel {

    var sortText;
    var colName;
    if (event != null) {
      if (event.srcElement.nodeName == 'SPAN') {
        var index = event.srcElement.innerHTML.indexOf("<i");
        colName = event.srcElement.innerHTML.substr(0, index);
        console.log(colName);
      }
      else {
        //colName= target.parentElement;
        if (event.target.parentElement.nodeName == 'SPAN') {
          var index = event.target.parentElement.innerHTML.indexOf("<i");
          colName = event.target.parentElement.innerHTML.substr(0, index);
          console.log(colName);
        }
      }
      var sortedBy;
      if (isASc) {
        sortedBy = "Ascending";
      }
      else {
        sortedBy = "Descending";
      }
      sortText = colName + " (" + sortedBy + ")";
    }

    if (dataTyp != "date") {
      if (dataTyp == "number") {
        listItems.sort(function (a, b) { return isASc ? (parseInt(a[column]) - parseInt(b[column])) : (parseInt(b[column]) - parseInt(a[column])) });
        isReverse = !isReverse;
      }
      else {
        listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
        isReverse = !isReverse;
      }
    }
    else {
      if (isASc == false) {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

      }
      else {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

      }
      isReverse = !isReverse;
      listItems = pageOfItem;
    }
    var response = {} as SortModel;
    response.listItem = listItems;
    response.isReverse = isReverse;
    response.sortText = sortText;
    return response;
  }
  commonValidateString(str:string){

    if(str=="")return true;
    var regex = new RegExp("^[a-zA-Z0-9 \n]+$");
    
    if (regex.test(str)) {
        return true;
    }
    else 
    return false;
  }
  commonValidateMobileString(str:string){

    if(str=="")return true;
    var regex = new RegExp("^[0-9]+$");
    
    if (regex.test(str)) {
        return true;
    }
    else 
    return false;
  }

  commonCheckSpecialCharacter(event: any){   
    // var k = event.keyCode;    
    //    if((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 ||k==46 || (k >= 48 && k <= 57)){
       
    //     return true;
    //    }
    //    event.preventDefault();
    //    return false;

    var regex = new RegExp("^[a-zA-Z0-9\r\n ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }
  commonCheckForMobileNo(event: any){   
   
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }
  commonCheckForEmail(event: any){   
   
    var regex = new RegExp("^[a-zA-Z0-9\r@. ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }

  commonSearch(tableColumn: any, itemList: any[], value: string) {

    var tableColumnObj = tableColumn;

    var newResultSet = itemList.filter(function (item) {
      console.warn("item", item);

      for (var key in tableColumnObj) {
        if (item[key] != null && item[key] != undefined &&
          item[key].toString().toLowerCase().includes(value.toLowerCase()))
          return true;
      }
      return false;
    });

    return newResultSet;
  }

  calculateDateDiff(dateSent: any) {
  
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    var today = new Date();
    var diffMs = (currentDate.valueOf() - dateSent.valueOf()); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
       
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var diffMonth = this.monthDiff(dateSent, currentDate);

    console.log(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until Christmas =)");

    if (diffHrs < 24 && diffDays == 0) {
      return diffHrs + " hours ago";
    }
    else if (diffDays <= 30 ){//&& diffMonth == 0) {
      if (diffDays == 1) {
        return "1 day ago";
      }
      return diffDays + " days ago";
    }
    else if (diffMonth >= 1 && diffMonth <= 4) {
      if (diffMonth == 1) {
        return "1 month ago";
      }
      return diffMonth + " months ago";
    }
    else {
      return dateSent.getDate() + " " + this.monthNames[dateSent.getMonth()] + " " + dateSent.getFullYear();
    }
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  commonSortBy2Column(listItems: any[], column: string, isASc: boolean, thenByColumn: string, thenByIsASc: boolean) {
    if (column == 'RemarkDateTimeString' || column == 'ticketCreatedOn') {
      // listItems.sort(function (a, b) { return ( (b[column]==null ||b[column]=='')?-1:(a[column]==null ||a[column]=='')?1:((new Date(a[column]) > new Date(b[column]) ? 1 : (new Date(a[column]) < new Date(b[column]) ? -1 : 0))))
      //    || sort(a[thenByColumn], b[thenByColumn], thenByIsASc) });

      listItems.sort(function (a, b) {
        return ((new Date(a[column]) > new Date(b[column]) ? 1 : (new Date(a[column]) < new Date(b[column]) ? -1 : 0)))
      });

    }
    else {
      listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) || sort(a[thenByColumn], b[thenByColumn], thenByIsASc) });
    }
  }

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}

export interface ChartModel {
  chartData: [];
  chartlabels: [];
  list: [];
}

export interface SortModel {
  listItem: any[];
  isReverse: boolean;
  sortText: string;
}
