import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {formatDate } from '@angular/common';
import * as internal from 'assert';
import { CommonMethods } from 'src/app/services/commonmethods';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  category: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  period: string = "";
  sortText: string = "";
  loggedInUserName: string = "";
  isList: boolean = true;  
  exportData:boolean=true;
  breadCrumbCategory="Complaints";
  totCount:number;
searchText="";
  items = [];
  pageOfItems: Array<any>;
  selectedZRMName:string="";
  selectedRMName:string="";
  zrmId:string;
  rmId:string;
  region:string;

  tableColumn = {
    accountId: "Account Id", accountName: "Account Name",
    totalOpenComplaint: "Open", totalCloseComplaint: "Closed",
    serviceCenter:"Service Center",region:"Region"
  };

  constructor(private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService,
    private modalService: NgbModal,private commonMethods: CommonMethods) {
      

    }

    
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  ngOnInit() {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("loggedinuserid");
    this.zrmId = localStorage.getItem("SelectedZRMId");
     this.rmId = localStorage.getItem("SelectedRMId");
     this.region = localStorage.getItem("SelectedRegion");

    this.getTicketCompalintSummaryList();
  }
  getTicketCompalintSummaryList() {
 
    this.isList = false;

    this.customHttpService.getDataWithAuthentication("/Complaint/GetTicketComplaintSummary?zrmId=" + this.zrmId+"&rmId="+this.rmId+"&region="+this.region)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList = true;

        this.totCount= this.items.reduce(function(prev,cur){
          return prev+cur.totalOpenComplaint;
        },0);

        var sortResponse = this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'totalOpenComplaint',false,'number'); 
        console.log(sortResponse);
        this.sortText="Open (Descending)";  
        this.reverse=sortResponse.isReverse;
        this.onChangePage(sortResponse.listItem.slice(0, 20));
      });
  }
  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length>0 || this.isList)&& this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }

  search(value: string): void {
    this.isList = false;

    if (value.length >= 3) {
      this.pageOfItems = this.commonMethods.commonSearch(this.tableColumn, this.allPageOfItems, value);
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
    this.items = this.pageOfItems;
    this.isList = true;
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);
   
    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if(item.classList!=undefined){
      item.classList.remove("selectBackColor");
      }
    });

    parent.classList.add("selectBackColor");

    var sortResponse= this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event, column,isASc,dataTyp);
    this.sortText=sortResponse.sortText; 
    this.reverse=sortResponse.isReverse
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  exportToExcel() {
    var callMethod: string = "";
    this.exportData=false;
    

    this.customHttpService.exportDataWithAuthentication("/Complaint/ExportTicketComplaintSummary?zrmId=" + this.zrmId+"&rmId="+this.rmId+"&region="+this.region)
      .subscribe(data => {
        this.exportData=true;
        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);
        var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = "RMTickets_Summary" + nowDate+".xls";
        anchor.href = url;
        anchor.click();

      });
  }

  navigateToDetails(accountId:string){
   
        //var index= accountId.indexOf("/");
        var accountNo= accountId.split('/')[0];
        var url = "tickets_detail/"+accountNo;
        window.open(url, "_blank");
      }
}
