import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FormGroup,FormBuilder,Validators,FormControl,AbstractControl } from '@angular/forms';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-rm-mapping',
  templateUrl: './rm-mapping.component.html',
  styleUrls: ['./rm-mapping.component.scss']
})
export class RMMappingComponent implements OnInit {
  userFormGroup: FormGroup;
  RomList = [];
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems: Array<any>;
  isEdit: boolean = false;
  isSave: boolean = false;
  closeResult = '';
  userType: string = '';
  parentUser: string;
  serviceCenter: string;
  name: string;
  mobile: string;
  email: string;
  errorMsg: string;
  successMsg: string;
  isSuccess: boolean = false;
  isError: boolean = true;
  sortText: string;
  reverse: boolean = false;
  scIsDisabled: boolean = false;
  isSaveDisabled: boolean = true;
  userId: number = 0;
  isDisabled: boolean = false;
  popupName: string;
  searchText = "";
  isZRMDisabled:boolean=false;
  id: number;
  userIdForDelete: number = 0;
  typeName: string = '';
  loggedinUserEmail: string = '';
  isUserDeleted: boolean = false;
  message: string = "";
  isDeleteSuccess: boolean = false;
  isUpradetoSameUserSelected: boolean = false;
  selectedRRMRoleItem: any;
  isRMRoleUpdatedSuccess: boolean = false;
  isRMRoleUpdatedFailure: boolean = false;
  RRMList: any;
  RRMListForTransfer: any;
  selectedRRMId: any;
  selectedTranferedRRMId: any;
  selectedTranferedRRMItem: any;
  isTransferedRRMSelected: boolean = false;
  radioButtonValue: any;
  checkSaveValidation: boolean = false;
  saveValidationMessage: any;
  sfdcAccountList= [];
  showDownloadAccountButton: boolean = false;
  rrmLeftCompanyValue: any;
  isRRMLeftCompany: boolean = false;
  isRRMTransferSaveDisable: boolean = false;
  ZRMUser:string="";
  zrmList  = [];
  emailVerifyModel :any={id: '', name: ''};
  rmempcode: string="";
  errorMessage :boolean = false;
  updateerror:boolean=false;
  alreadyExists:boolean = false;
  emailmsg :boolean = false;

  tableColumn = {
    typeName: "Type", name: "Full Name",
    mobile: "Mobile Number",
    email: "Email"
  };

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private _formBuilder: FormBuilder,
    private customHttpService: CustomhttpService, private commonMethods: CommonMethods,
    private modalService: NgbModal) {

  }

  ngOnInit() {
    this.loggedinUserEmail = localStorage.getItem("loggedinemail");
    console.log(this.loggedinUserEmail);
    this.getRMMappingList();
    debugger;
    this.userFormGroup = this._formBuilder.group({
      
      email:  ['', [Validators.required, Validators.maxLength(50),
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/)]],
      name:['', [Validators.required, Validators.maxLength(50)]],
      mobile: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^\d{10}$/)]],
    })
    
  }


  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
  getRMMappingList() {
    debugger
    this.customHttpService.getDataWithAuthentication("/User/GetRMMappingList")

      .subscribe(data => {

        console.log(data.responseData)

        // for (let i = 0; i < data.responseData.length; i++) {
        //   data.responseData[i].isSave = false;
        // }

        this.RomList = data.responseData;
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.RRMList = data.responseData.filter(x => x.typeName == 'RRM');
        this.RRMListForTransfer = data.responseData.filter(x => x.typeName == 'RRM' && (x.parentId !=null && x.parentId !=''));
        debugger;
        this.RRMList.sort((a,b) => a.name > b.name ? 1: -1);
        this.RRMListForTransfer.sort((a,b) => a.name > b.name ? 1: -1);
        this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, null, 'regionName', true);
        this.sortText = "Region (Ascending)";
      });
  
  }

  getZRMList()
  {
    debugger;
    this.customHttpService.getDataWithAuthentication("/User/GetZRMList")
    .subscribe(data => {
      debugger;
      this.zrmList = data.responseData;
      console.log(data.responseData)
    });
  
  }

  checkEmailType(email:string){
    debugger;
    this.emailVerifyModel={};
  
    this.customHttpService.getDataWithAuthentication("/User/GetUserDetails?email="+this.email)
    .subscribe(data => {
      debugger;
      if(data.responseData!=null){
        this.emailVerifyModel = data.responseData;
        this.errorMessage = false;
        console.log(data.responseData)
      }
      else{
     
          this.errorMessage = true;
          this.emailVerifyModel.name= '';
       
      }
    
    });
   }
  
   
  checkLoading() {
    if (this.items != undefined && this.items != null && this.items.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  editUser(item) {
    debugger;
    item.isEdit = true;
    this.isEdit = true;
    this.alreadyExists = false;
  }

  cancelItem(item) {
    item.isEdit = false;
  }
  
  openAddUser(content, item: any) {
    debugger;
    this.errorMsg = "";
    this.successMsg = "";
    this.isError = false;
    
    if (item == null || item == undefined) {
      console.log("item null");
      this.userType = null;
      this.ZRMUser = "";
      this.serviceCenter = "";
      this.emailVerifyModel.name="";
      this.mobile = "";
      this.email = "";
      this.emailVerifyModel.id = "";
      this.isDisabled = false;
      this.isZRMDisabled = false;
      this.errorMessage=false;
      this.alreadyExists= false;
      this.userId = 0;
      this.emailmsg = false;
      this.popupName = "Add New User";
      this.getZRMList();
    }
    else {
      console.log("item not null");
      this.userType = item.typeName;
      this.emailVerifyModel.id = item.externalRefId;
      this.ZRMUser = item.parentId;
      this.emailVerifyModel.name = item.name;
      this.mobile = item.mobile;
      this.email = item.email;
      this.userId = item.id;
      this.checkUserTyp();
      this.isDisabled = true;
      this.isZRMDisabled = true;
      this.errorMessage = false;
      this.updateerror = true;
      this.alreadyExists= false;
      this.popupName = "Edit User";
    }
    console.log("type" + this.userType);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: "custom-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addUser() {
    if (this.email.toLowerCase().includes('@hicare.in') || this.email.toLowerCase().includes('@rentokil')) {
      // var response=await this.commonUpdateUser(0,this.userType,this.region,this.serviceCenter,this.fullName,this.mobile,this.email);

      this.customHttpService.postData("/User/AddUser",
        {
          "Id": this.userId,
          "TypeName": this.userType,
          "parentUserId": this.ZRMUser,
          "FullName": this.emailVerifyModel.name.trim(),
          "MobileNumber": this.mobile,
          "EMail": this.email.trim(),
          "Emp_Code":this.emailVerifyModel.rmEmpCode,
          "ExternalRefIdRM":this.emailVerifyModel.id
        }).subscribe(data => {
          debugger;
          if (data.isSuccess) {
            this.isError = false;
            this.isSuccess = true;
          

            this.successMsg = (this.userId == 0) ? "User Saved Successfully!" : "User Updated Successfully!";
            setTimeout(() => { document.getElementById("btnCloseModel").click() }, 5000);

            this.RomList = [];
            this.getRMMappingList();
          }
          else {
           
            this.isError = true;
            this.errorMsg = data.errorMessage;
            this,this.alreadyExists = true;
            
          }
        });
    }
    else {
      this.isError = true;
      //this.errorMsg = "Please enter valid Email!"
    }
  }

  closeModel() {

  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(parent.parentElement.classList);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    var sortResponse = this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, event, column, isASc, dataTyp);
    this.sortText = sortResponse.sortText;
    this.reverse = sortResponse.isReverse
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }



  onKeyPressCheckSpecialCharacter(event: any) {
    return this.commonMethods.commonCheckSpecialCharacter(event);

  }
  onKeyPressCheckForMobile(event: any) {
    debugger;
    return this.commonMethods.commonCheckForMobileNo(event);
  }
  onKeyPressCheckForEmailSpecialCharacter(event: any) {
    return this.commonMethods.commonCheckForEmail(event);
  }
  changeCheckTxt(event: any) {
    debugger;
    var chkRemark = this.commonMethods.commonValidateString(event);
    //item.isSpecialChar= !chkRemark;
  }
  changeCheckMobile(event: any) {
    debugger;
    var chkRemark = this.commonMethods.commonValidateMobileString(event);
  }

  search(value: string) {

    var tableColumnObj = this.tableColumn;

    if (value.length >= 3) {
      this.pageOfItems = this.allPageOfItems.filter(function (item) {
        console.warn("item", item);

        for (var key in tableColumnObj) {
          if (item[key] != null && item[key] != undefined &&
            item[key].toString().toLowerCase().includes(value.toLowerCase()))
            return true;
        }
        return false;
      });
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
    this.items = this.pageOfItems;
    //this.isList=true;

  }

  checkUserTyp() {
    debugger
    if (this.userType == "ZRM" || this.userType =='RM Admin') {
      this.isZRMDisabled = true;
      this.ZRMUser = '';

    }
    else {
    
      this.isZRMDisabled = false;

    }
  }
 
  onEmailChange(event: any) {
    debugger;
    if (event.target.value.trim() === '') {
        this.emailVerifyModel.name = '';
        this.alreadyExists = false;
        this.errorMessage = false;
    }
  }
  checkSaveDisabled() {
    debugger
    if(this.userType == "RRM"){
      if (this.userType != undefined && this.userType != null  && this.emailVerifyModel.name!= ''
      && this.mobile != '' && this.email!= ''&& this.ZRMUser!='') {
        if (this.ZRMUser != '') {

          return true;

        }
      }
    }else if(this.userType == "RM Admin" || this.userType == "ZRM")
      {
      if (this.userType != undefined && this.userType != null && this.emailVerifyModel.id != ''
      && this.mobile != '' && this.email!= '') {
        if (this.ZRMUser == '') {
          return true;
        }
      }
    }
    
  } 
     

  open(content, id, name, typeName) {
    debugger;
    if (this.isDisabled) {
      return true;
    }

    this.userIdForDelete = id;
    this.name = name;
    this.typeName = typeName;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  deleteUser() {
    debugger;
    console.log(this.userIdForDelete);
    this.customHttpService.postData("/User/DeleteUser", this.userIdForDelete).subscribe(data => {
      if (data.isSuccess) {
        this.isDeleteSuccess = true;
        this.isUserDeleted = true;
        this.message = "User Deleted Successfully!";
        this.getRMMappingList();
      }
      else {
        this.isDeleteSuccess = false;
        this.isUserDeleted = false;
        this.message = "Something went wrong!";
      }
    });
  }

  openUpgradeUserRole(content, item: any) {
    debugger;
    this.isUpradetoSameUserSelected = false;
    this.radioButtonValue = 'no';
    this.isTransferedRRMSelected = false;
    this.selectedRRMId = null;
    this.selectedTranferedRRMId = null;
    this.isRMRoleUpdatedSuccess = false;
    this.isRMRoleUpdatedFailure = false;
    this.checkSaveValidation = false;
    this.showDownloadAccountButton = false;
    this.sfdcAccountList = [];
    this.rrmLeftCompanyValue ='no';
    this.isRRMLeftCompany = false;
    this.isRRMTransferSaveDisable = false;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: "custom-class" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onRadioButtonChange(event: any) {
    console.log(event.target.value);
    this.radioButtonValue = event.target.value;
    this.isUpradetoSameUserSelected = event.target.value == 'yes' ? true : false;
  }

  onRRMLeftCompany(event: any) {
  this.rrmLeftCompanyValue = event.target.value;
  this.isRRMLeftCompany = event.target.value == 'yes' ? true : false;
  }

  onRRMSelection() {
    this.selectedRRMRoleItem = this.RRMList.filter(x => x.id == this.selectedRRMId);
    console.log("selectedRRM", this.selectedRRMRoleItem);
  }

  onTransferedRRMSelection() {
    this.isTransferedRRMSelected = this.selectedTranferedRRMId != null ? true : false;
    this.selectedTranferedRRMItem = this.RRMListForTransfer.filter(x => x.id == this.selectedTranferedRRMId);
    console.log("selectedRRM", this.selectedTranferedRRMItem);
  }

  updateRRMRole() {
    this.showDownloadAccountButton = false;
    this.sfdcAccountList = [];

    try {
      if (this.selectedRRMId != null) {
        if (this.isUpradetoSameUserSelected == true || this.isTransferedRRMSelected == true) {
          this.isRRMTransferSaveDisable = true;
          if (this.isUpradetoSameUserSelected == true) {
          this.customHttpService.postData("/User/UpgradeRRMRole", this.selectedRRMRoleItem[0]).subscribe(data => {
            if (data.isSuccess) {
              this.selectedRRMId = null;
              this.isUpradetoSameUserSelected = false;
              this.isTransferedRRMSelected = false;
              this.selectedTranferedRRMId = null;
              this.radioButtonValue = 'no';
              this.isRMRoleUpdatedSuccess = true;
              this.message = "RRM Role Updated Successfully!";
              this.isRRMTransferSaveDisable = false;
              this.getRMMappingList();
              setTimeout(() => {this.isRMRoleUpdatedSuccess = false;}, 5000);
            }
            else {
              this.isRMRoleUpdatedFailure = true;
              this.message = "Something went wrong!";
              this.isRRMTransferSaveDisable = false;
              setTimeout(() => {this.isRMRoleUpdatedFailure = false;}, 5000);
            }
          });
        } else if (this.isTransferedRRMSelected == true) {
            var rmModel = {
              "rrmId": this.selectedRRMRoleItem[0].id,
              "rrmExtRefId": this.selectedRRMRoleItem[0].externalRefId,
              "rrmType": this.selectedRRMRoleItem[0].typeName,
              "transRrmId": this.selectedTranferedRRMItem[0].id,
              "transRrmExtRefId": this.selectedTranferedRRMItem[0].externalRefId,
              "transRrmParentId": this.selectedTranferedRRMItem[0].parentId,
              "transRrmType": this.selectedTranferedRRMItem[0].typeName,
              "rrmLeftCompany": this.isRRMLeftCompany
            }
            this.customHttpService.postData("/User/RRMTransfer", rmModel).subscribe(data => {
              if (data.isSuccess) {
                this.selectedRRMId = null;
                this.isUpradetoSameUserSelected = false;
                this.isTransferedRRMSelected = false;
                this.selectedTranferedRRMId = null;
                this.radioButtonValue = 'no';
                this.isRMRoleUpdatedSuccess = true;
                this.message = "RRM Role Updated Successfully!";
                if (data.responseData != '') {                  
                  this.sfdcAccountList = data.responseData;
                  this.showDownloadAccountButton = true;
                }
                this.isRRMTransferSaveDisable = false;
                this.getRMMappingList();
                setTimeout(() => { this.isRMRoleUpdatedSuccess = false; }, 30000);
              }
              else {
                this.isRMRoleUpdatedFailure = true;
                this.message = data.errorMessage;
                this.isRRMTransferSaveDisable = false;
                if (data.responseData != '') {
                  this.sfdcAccountList = data.responseData;
                  this.showDownloadAccountButton = true;
                }
                setTimeout(() => { this.isRMRoleUpdatedFailure = false; }, 30000);
              }
            });
        }
      }
      else {
        this.checkSaveValidation = true;
        this.saveValidationMessage = 'Please select whether you want to transfer to another RRM or to selected RRM!';
        setTimeout(() => { this.checkSaveValidation = false; }, 8000);

      }
    }
    else {
      this.checkSaveValidation = true;
      this.saveValidationMessage = 'Please select RRM!';
      setTimeout(() => {this.checkSaveValidation = false;}, 5000);
    }
          
    }
    catch (error) {
      debugger;
      console.log(error);
      this.isRRMTransferSaveDisable = false;
      this.message = "Something went wrong. Please try again later!";
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  downloadTransferRRMExcel() {
    var rrmTransferExcelReport: rrmTransferExcelModel[] = [];

    for(var i=0; i<this.sfdcAccountList.length;i++) {      
      var rrmModel : rrmTransferExcelModel = {} as rrmTransferExcelModel;
      rrmModel.CustomerId = this.sfdcAccountList[i].customer_Id__c;
      rrmModel.TransferredStatus = this.sfdcAccountList[i].is_Account_Transferred? 'Transferred': 'Not Transferred';

      rrmTransferExcelReport.push(rrmModel);
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rrmTransferExcelReport);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "Transferred_Accounts_Report");
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + "_" + new Date().getTime() + EXCEL_EXTENSION);
  }
}

export interface rrmTransferExcelModel {
  CustomerId: string;
  TransferredStatus: string;
}
