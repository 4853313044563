import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonMethods } from 'src/app/services/commonmethods';
import { formatDate } from '@angular/common';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tickets-detail',
  templateUrl: './tickets-detail.component.html',
  styleUrls: ['./tickets-detail.component.scss']
})
export class TicketsDetailComponent implements OnInit {
  category: string = "";
  selectedZRMName: string;
  selectedRMName: string;
  selectedRegion: string;
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  period: string = "";
  sortText: string = "";
  loggedInUserName: string = "";
  isList: boolean = true;
  exportData: boolean = true;
  accountId: string;
  breadCrumbAccountId: string;
  breadCrumbAccountName: string;
  loggedinUserEmailid: string;
  isSave: boolean = false;
  statusList = [];
  items = [];
  interactionList = [];
  pageOfItems: Array<any>;
  searchText = "";
  zrmId: string;
  rmId: string;
  region: string;
  isShowComplaint: boolean = false;

  tableColumn = {
    ticketCreatedOn: "Created On", ticketModifiedOn: "Modified On",
    subject: "Title", //descriptionText: "Description",
    fdTicketId: "Ticket Id",
    complaintType: "Complaint Type", status: "Status", duration: "Age",
    action: "Action", accountId: "Account Id", accountName: "Account"
  };

  constructor(private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService,

    private modalService: NgbModal, private commonMethods: CommonMethods) {
    this.accountId = this.activatedRoute.snapshot.params.accountId;

    // this.breadCrumbAccountId="Tickets Detail For Account ";
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  ngOnInit() {

    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("loggedinuserid");
    this.zrmId = localStorage.getItem("SelectedZRMId");
    this.rmId = localStorage.getItem("SelectedRMId");
    this.region = localStorage.getItem("SelectedRegion");
    this.loggedinUserType = localStorage.getItem("loggedinusertype");

    //this.statusList = this.commonMethods.ticketStatusList;
    //this.getTicketStatus();
    if (this.accountId != "0") {
      this.isShowComplaint = true;
    }
    this.getTicketsCompalintAccountDetailList();
  }

  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length > 0 || this.isList) && this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }
  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if (item.classList != undefined) {
        item.classList.remove("selectBackColor");
      }
    });

    parent.classList.add("selectBackColor");

    var sortResponse = this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, event, column, isASc, dataTyp);
    
    this.sortText = sortResponse.sortText;
    this.reverse = sortResponse.isReverse;

    if(column=="ticketCreatedOn"){
      if(isASc)this.sortText="Age (Descending)";
      else this.sortText="Age (Ascending)";
    }
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  search(value: string): void {
    debugger;
    this.isList = false;
    var filterList = this.allPageOfItems;

    if (!this.isShowComplaint) {
      filterList = this.allPageOfItems.filter((val) =>
        (val.subject != null && !val.subject.toLowerCase().includes('autoemailer')));
    }

    if (value.length >= 3) {
      this.pageOfItems = this.commonMethods.commonSearch(this.tableColumn, this.allPageOfItems, value);
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
    this.items = this.pageOfItems;
    this.isList=true;
    console.log(this.items);
    this.filterByAutoGenerated();
  }

  getTicketsCompalintAccountDetailList() {
    debugger;
    this.isList = false;
    var url = "/Complaint/GetTicketComplaintDetails?accountId=" + this.accountId;

    if (this.accountId == "0") {
      url = "/Complaint/GetMyTicketComplaints?zrmId=" + this.zrmId + "&rmId=" + this.rmId + "&region=" + this.region;
    }

    this.customHttpService.getDataWithAuthentication(url)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList = true;
        if (this.accountId != "0") {
          this.breadCrumbAccountId = data.responseData[0].accountId;
          this.breadCrumbAccountName = data.responseData[0].accountName;
        }

        let currentDate = new Date();
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].duration = this.commonMethods.calculateDateDiff(this.items[i].ticketCreatedOn);
          this.items[i].sortOrder = this.items[i].status == "Closed" ? 0 : 1;

        }
        //this.commonMethods.commonSortBy2Column(this.items, "sortOrder", false, "ticketCreatedOn", false);
        //this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'ticketCreatedOn',true,'date'); 
        //this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'subject',true); 
        this.sortText = "Age";      
        this.showAutoComplaints(null);

        this.searchText = "Resolved - Client Confirmation Pending";
        setTimeout(() => {
          this.search(this.searchText);
          //this.sortText = "Status, Duration";
          this.onChangePage(this.items.slice(0, 20));
        }, 2000);

      });
  }

  filterByAutoGenerated() {
    if (this.isShowComplaint) {
      //this.items = this.allPageOfItems;
    }
    else {
      this.items = this.items.filter((val) =>
        (val.subject != null && !val.subject.toLowerCase().includes('autoemailer')));

    }
    this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'duration',false,'date'); 
    
    this.sortText = "Age (Ascending)";    

  }

  showAutoComplaints(event) {
    debugger;
    if (event != null) {
      this.isShowComplaint = event.currentTarget.checked;
    }

    this.search(this.searchText);

    // if (this.isShowComplaint) {
    //   this.items = this.allPageOfItems;
    // }
    // else {
    //   this.items = this.allPageOfItems.filter((val) =>
    //     (val.subject != null && !val.subject.toLowerCase().includes('autoemailer')));

    // }
    // this.commonMethods.commonSortBy2Column(this.items, "sortOrder", false, "ticketCreatedOn", false);
    // this.sortText = "Not Closed Complaints, Duration";
    //this.searchText = "";
  }

  getInteractionDetails(item) {

    console.log(item.isCollapsed);
    if (!item.isCollapsed) {
      this.customHttpService.getDataWithAuthentication("/Complaint/GetB2bComplaintInteractionDetails?accountId=" + this.accountId +
        "&complaintId=" + item.complaintId)
        .subscribe(data => {
          console.log(data.responseData)
          this.interactionList = data.responseData;

          item.isCollapsed = true;

        });
    }
    else {
      item.isCollapsed = false;
    }
  }

  exportToExcel() {
    var callMethod: string = "";
    this.exportData = false;

    var url = "/Complaint/ExportTicketComplaintDetail?accountId=" + this.accountId;
    var excelName = "RM_TicketComplaint_Detail";

    if (this.accountId == "0") {
      url = "/Complaint/ExportMyTicketComplaintDetail?zrmId=" + this.zrmId + "&rmId=" + this.rmId + "&region=" + this.region;
      excelName = "RM_MyTicketComplaint_Detail";
    }


    this.customHttpService.exportDataWithAuthentication(url)
      .subscribe(data => {
        this.exportData = true;
        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);
        var nowDate = formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = excelName + nowDate + ".xls";
        anchor.href = url;
        anchor.click();
      });
  }

  navigateToInteractionDetail(ticketId: number) {
    var url = "tickets_interaction_detail/" + ticketId;
    window.open(url, "_blank");
  }

  navigateToAccountDetail(accountId:string){ 
    debugger;
    var accountNo= accountId.split('/')[0];
    var url = "tickets_detail/"+accountNo;
    window.open(url, "_blank");
  }

  saveStatus(item) {
    debugger;
    console.log(item.action);
    if (item.action != "Closed") {

      this.customHttpService.getDataForAbsoluteUrl(this.commonMethods.ticketDomain + "/Ticket/ChangeStatus?ticketId=" + item.fdTicketId + "&status=" + item.action.replace("-", "").replace(" ", ""))
        .subscribe(data => {
          // if(data.isSuccess)
          // {
          debugger;
          this.customHttpService.postData("/Complaint/ChangeComplaintStatus", { "Status": item.action, "TicketId": item.fdTicketId }).subscribe(data => {
            this.isSave = true;
            setTimeout(() => { this.isSave = false; }, 5000);
          });


          // }
        });
      // this.isSave = true;
      // item.status=item.action;
      // setTimeout(() => { this.isSave = false; }, 5000);
    }
  }
  checkStatus(status) {
    // if (status == 'Closed' || status == 'Resolved')
    //   return false;
    // else
    //   return true;
    return true;
  }
  getTicketStatus() {
    debugger;
    this.customHttpService.getDataWithAuthentication("/Complaint/GetComplaintStatusMasterList?userTypeName=" + this.loggedinUserType)
      .subscribe(data => {
        console.log(data.responseData);

        this.statusList = data.responseData;

      })
  }

}

