import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { CustomhttpService } from '../services/customhttp.service';
import { CommonMethods } from 'src/app/services/commonmethods';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/services/dateformat';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})


export class DashboardComponent implements OnInit {

  loggedInUserName: string = "";
  loggedinUserEmail: string = "";
  loggedinUserMobile: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  selectedRMId: any = {};
  selectedZRMId: any = {};
  selectedRMItem: any = {};
  selectedZRMItem: any = {};
  rrmListFromServer: any = [];
  rrmList: any = [];
  zrmList: any = [];
  selectedRegion: string = "";
  selectedRegionDisplay: string = "";
  complaintNumbers: any = [];
  redAccountNumbers: any = [];
  invoiceNumbers: any = {};
  srNumbers: any = {};
  srLast6Numbers: any = {};
  srRedNumbers: any = {};

  isSRList: boolean = true;
  isSRBaseList:boolean=true;
  isSRLast6List:boolean=true;
  rbtNumbers: any = {};
  farmingNumber: any = {};
  amtIn: number = 100000;
  renewalNumber: any = {};
  redAccountList: any = {};
  redAccountRemarkList: any = {};
  isAllLoad: boolean = false;
  closeResult = '';
  watchListNumbers: any = {};
  watchListServerNumbers: any = {};
  selectedSortForAccountWatchList: string = "Red";
  selectedSortthenByForAccountWatchList: string = "CV";
  selectedWatchListAccount: any = {};
  selectedFilterForAccountWatchList: string = "All";
  noRemarks: boolean = false;
  selectedRedAccount: string = "";
  selectedRedAccountId: string = "";
  redAccRemark: string = "";
  redAccDueDate: NgbDate;
  watchlistRedAccountList: any = [];
  isRemark: boolean = false;
  minPickerDate: NgbDate;
  assignedToUserId: string;
  assignedToUserMail: string = null;
  selectedRedCustomerId: string = "";
  assignedToUserList: any = [];
  selectedFilterForAgewise: string = "All";
  remarkList: any = [];
  isRemarkList: boolean = false;
  renewalPerformanceList: any = [];
  isRenewalPerformanceList: boolean = false;
  redAccountBaseList: any = {};
  isRedAccountBaseList: boolean = false;
  farmingOrderList: any = [];
  isFarmingOrderList: boolean = false;
  priceEscalationList: any ={};
  isPriceEscalationList: boolean = false;
  profitabilityList: any = {};
  isProfitabilityList: boolean = false;
  svBaseLoadButton: boolean = false;
  svLast6MonthLoadButton : boolean = false;
  svRedAccountBaseLoadButton: boolean = false;
  isSVBaseAPIHit: boolean = false;
  isSVLast6MonthAPIHit: boolean = false;
  isSVRedAccountBaseAPIHit: boolean = false;
  isProfitabilityAPIHit: boolean = false;
  profitabilityLoadButton: boolean = false;
  onBoardingNotificationCount = 0;
  uploadedWagesFile: any;
  uploadedSalaryFile: any;
  fileName: string;
  isWagesExcelUploaded: boolean = false;
  isSalaryExcelUploaded: boolean = false;

  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;

  constructor(private customhttpService: CustomhttpService, private router: Router, private cdRef: ChangeDetectorRef, private modalService: NgbModal,
    private commonMethods: CommonMethods, private decimalPipe: DecimalPipe) {
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  roundNumber(no: any) {
    if (no != undefined && no != null && no != "")
      return parseFloat(no).toFixed(2) + " L";
    else
      return "0.00";
  }


  navigateToSVBase(period: string, srStatus: string) {
    var url = "SVBase/" + period + "/" + srStatus;
    window.open(url, "_blank");
  }

  navigateToSVCount(period: string, category: string) {
    var url = "RenewalOrder/" + period + "/" + category;
    window.open(url, "_blank");
  }
  navigateToAccountSummary(healthType: string) {
    var url = "AccountSummary/" + healthType;
    window.open(url, "_blank");
  }

  navigateToDelivery(period: string, srStatus: string) {
    var url = "DeliveryRBT/" + period + "/" + srStatus;
    window.open(url, "_blank");
  }
  navigateToFarming(period: string, srStatus: string) {
    var url = "FarmingOrder/" + period + "/" + srStatus;
    window.open(url, "_blank");
  }

  navigateToComplaint(segment: string, period: string, srStatus: string) {
    var url = "complaint/" + segment + "/" + period + "/" + srStatus;
    window.open(url, "_blank");
  }

  navigateToRedAccount(period: string, category: string) {
    var url = "RedAccount/" + period + "/" + category;
    window.open(url, "_blank");
  }

  navigateToInvoices(period: string, category: string) {
    var url = "Invoices/" + period + "/" + category;
    window.open(url, "_blank");
  }
  navigateToRenewal(period: string, category: string) {
    var url = "RenewalOrder/" + period + "/" + category;
    window.open(url, "_blank");
  }

  navigateToPerformanceRenewal(period: number, category: string, year: number) {
    var url = "RenewalOrder/" + period + "/"+ "RenewalPerformanceBase"+"/" + category + "/" + year;
    window.open(url, "_blank");
  }

  navigateToRemarkBasedRenewal(period: number, category: string, year: number) {
    var url = "RenewalOrder/" + period + "/"+ "RemarkBasedRenewal"+"/" + category + "/" + year;
    window.open(url, "_blank");
  }

  navigateToPriceEscalation(period: number) {
    var url = "PriceEscalation/" + period;
    window.open(url, "_blank");
  }

  navigateToProfitability(period: number) {
    var url = "Profitability/" + period;
    window.open(url, "_blank");
  }



  getRMList() {
    this.customhttpService.getDataWithAuthentication("/User/GetRMHeirarchy?userId=" + this.loggedinUserId + "&userTypeName=" + this.loggedinUserType).subscribe(data => {
      console.log(data);
      debugger;
      if (data.isSuccess) {
        this.rrmListFromServer = data.responseData.rrmList;
        this.rrmList = data.responseData.rrmList;
        this.zrmList = data.responseData.zrmList;
        this.selectedZRMId = data.responseData.zrmList[0].id;
        this.selectedRegion = "All Regions";
        this.onZRMChange();
        this.onRRMChange();
        this.onRegionChange();
        this.fetchList();

      }
    });
  }

  fetchList() {

    this.isSVBaseAPIHit = false;
    this.isSRBaseList=true;
    this.svBaseLoadButton = false;
    this.isSVLast6MonthAPIHit = false;
    this.isSRLast6List=true;
    this.svLast6MonthLoadButton = false;
    this.isSVRedAccountBaseAPIHit = false;
    this.isSRList = true;
    this.svRedAccountBaseLoadButton = false;
    this.isProfitabilityAPIHit = false;
    this.profitabilityLoadButton = false;
    this.isProfitabilityList = true;
    this.srNumbers = {};
    this.srLast6Numbers = {};
    this.srRedNumbers ={};

    this.isAllLoad = false;
    this.resetField();
    this.selectedZRMItem = this.zrmList.filter(p => p.id == this.selectedZRMId);
    console.log(this.selectedZRMItem);
    localStorage.setItem("SelectedZRMId", this.selectedZRMId);
    localStorage.setItem("SelectedZRMName", this.selectedZRMItem[0].name);
    this.selectedRMItem = this.rrmList.filter(p => p.id == this.selectedRMId);
    console.log(this.selectedRMItem);
    this.selectedRegionDisplay = this.selectedRegion;
    localStorage.setItem("SelectedRMId", this.selectedRMId);
    localStorage.setItem("SelectedRMName", this.selectedRMItem[0].name);
    localStorage.setItem("SelectedRegion", this.selectedRegion);
    this.getRedAccountWatchListNumbers();
    this.getComplaintNumbers();
   // this.getSRNumbers();
    //this.getRedAccountNumbers();
    this.getInvoiceNumbers();
    //this.getRBTNumbers();
    this.getFarmingOrder();
    //this.getRenewalOrder(); //Renewal Base commented for now
    this.getAllRMList();
    this.getRemark();
    this.getRenewalPerformance();
    this.getPriceEscalation();
    this.GetNotificationOfOnBoardingRM(this.loggedinUserId);
    //this.getProfitability(); 

  }

  ngOnInit() {
    this.loggedInUserName = localStorage.getItem("loggedinusername");
    this.loggedinUserEmail = localStorage.getItem("loggedinemail");
    this.loggedinUserMobile = localStorage.getItem("loggedInMobileNumber");
    this.loggedinUserType = localStorage.getItem("loggedinusertype");
    this.loggedinUserId = localStorage.getItem("loggedinuserid");
    this.getRMList();
    this.minPickerDate = new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() + 1);

  }

  resetField() {
    this.complaintNumbers = [];
    this.srNumbers = {};
    this.farmingNumber = {};
    this.rbtNumbers = {};
    this.redAccountNumbers = [];
    this.invoiceNumbers = {};
    this.renewalNumber = {};
    this.remarkList = [];
    this.redAccountRemarkList = {};
    this.renewalPerformanceList = [];
    this.farmingOrderList = [];

  }

  checkLoading() {
    if (this.complaintNumbers.length > 0 //&& this.srNumbers.lastMonth != undefined

         && this.invoiceNumbers.total != undefined && this.isRemarkList == true &&
          this.isRenewalPerformanceList == true && this.isFarmingOrderList == true &&
           this.isPriceEscalationList == true 
           //&& this.isProfitabilityList == true
      // this.renewalNumber.thisMonthDue != undefined

      
      ) {
      this.isAllLoad = true;
      return true;
    }
    else
      return false;
  }

  checkWachListLoading() {

    if (this.watchListNumbers != null && this.watchListNumbers.redAccountList != undefined) {
      return true;
    }
    else {
      return false;
    }
  }

  onZRMChange() {
    console.log(this.selectedZRMId);
    if (this.selectedZRMId == "All ZRM") {
      this.rrmList = this.rrmListFromServer
    }
    else {
      this.rrmList = this.rrmListFromServer.filter(p => p.parentId == this.selectedZRMId || p.id == "All RRM");
    }
    this.selectedRMId = this.rrmList[0].id;
  }

  onRRMChange() {
    console.log(this.selectedRMId);
  }

  onRegionChange() {

  }

  getComplaintNumbers() {

    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService.getDataWithAuthentication("/Complaint/GetComplaintForDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          this.complaintNumbers = data.responseData;
        }
      });

  }

  getRedAccountWatchListNumbers() {

    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    this.watchListNumbers = null;
    this.customhttpService.getDataWithAuthentication("/Account/GetAccountWatchListForDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&accountType=" + this.selectedFilterForAccountWatchList +
      "&withOutRemarks=" + this.noRemarks + "&serviceAge=" + this.selectedFilterForAgewise).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          this.watchListNumbers = data.responseData;
          //new change
          //this.watchlistRedAccountList=this.watchListNumbers.redAccountList;

          this.watchListServerNumbers = JSON.parse(JSON.stringify(data.responseData));
          //this.filterAccountWatchList();
          this.sortAccountWatchList();
        }
      });

  }

  
  getSRNumbers(svType:string) {

    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    if((this.isSVBaseAPIHit && svType=="SVBase")
    || (this.isSVLast6MonthAPIHit && svType=="SVLast6Month")
    || (this.isSVRedAccountBaseAPIHit && svType=="SVRedAccount") ) {
      return;
    }

    if(svType=="SVRedAccount"){
      this.isSRList=false;
      this.svRedAccountBaseLoadButton = true;
    }
    else if(svType=="SVBase"){
      this.isSRBaseList=false;
      this.svBaseLoadButton = true;
    }
    else{
      this.isSRLast6List=false;
      this.svLast6MonthLoadButton = true;
    }

    this.customhttpService.getDataWithAuthentication("/SR/GetSRForDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region+"&svType="+svType).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {

          console.log(data);
          if(svType=="SVBase"){
          this.srNumbers = data.responseData;
          this.isSRBaseList=true;
          this.svBaseLoadButton = false;
          this.isSVBaseAPIHit = true;
          }
          else if(svType=="SVLast6Month"){
            this.srLast6Numbers=data.responseData;
            this.isSRLast6List=true;
            this.svLast6MonthLoadButton = false;
            this.isSVLast6MonthAPIHit = true;
          }
          else{
            this.srRedNumbers=data.responseData;
            this.isSRList = true;
            this.svRedAccountBaseLoadButton = false;
            this.isSVRedAccountBaseAPIHit = true;
          }        
        }
        else {
          this.isSRList = true;
          this.isSRLast6List=true;
          this.isSRBaseList=true;

          if (svType == "SVBase") {
            this.svBaseLoadButton = false;
          }
          else if (svType == "SVLast6Month") {
            this.svLast6MonthLoadButton = false;
          }
          else {
            this.svRedAccountBaseLoadButton = false;
          }

        }
      });
  };

  getFarmingOrder() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService.getDataWithAuthentication("/Order/GetFarmingOrderDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log("farming",data);
        if (data.isSuccess) {
          this.farmingOrderList = data.responseData;
          this.isFarmingOrderList = true;
        }
        else{
          this.isFarmingOrderList = false;
        }
      });
  };

  getRBTNumbers() {

    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService.getDataWithAuthentication("/SR/GetRBTDeliveryDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        //console.log(data);
        if (data.isSuccess) {
          console.log("RBT" + data.responseData);
          this.rbtNumbers = data.responseData;
        }
      });
  };

  getRedAccountNumbers() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService.getDataWithAuthentication("/Order/GetRedAccountsForDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          this.redAccountNumbers = data.responseData;
        }
      });

  }

  getInvoiceNumbers() {

    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService.getDataWithAuthentication("/Invoice/GetInvoicesForDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          this.invoiceNumbers = data.responseData;
        }
      });

  }

  getRenewalOrder() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    console.log("zrmId: " + zrmId + " rmId=" + rmId + "&region=" + region)

    this.customhttpService.getDataWithAuthentication("/Order/GetRenewalOrderDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          //debugger;
          this.renewalNumber = data.responseData;
        }
      });
  }

  getRenewalPerformance() {
    debugger;
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    console.log("zrmId: " + zrmId + " rmId=" + rmId + "&region=" + region)

    this.customhttpService.getDataWithAuthentication("/Order/GetRenewalPerformanceDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          debugger;
          this.renewalPerformanceList = data.responseData;
          this.isRenewalPerformanceList = true;
        }
        else{
          this.isRenewalPerformanceList = false;
        }
      });
  }

  getPriceEscalation(){
    debugger;
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    console.log("zrmId: " + zrmId + " rmId=" + rmId + "&region=" + region)

    this.customhttpService.getDataWithAuthentication("/Order/GetPriceEscalationDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          debugger;
          this.priceEscalationList = data.responseData;
          this.isPriceEscalationList = true;
        }
        else{
          this.isPriceEscalationList = false;
        }
      });
  }

  getProfitability(){
    debugger;
    this.profitabilityLoadButton = true;
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    if(!this.isProfitabilityAPIHit){
      this.customhttpService.getDataWithAuthentication("/Order/GetProfitabilityDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          debugger;
          this.profitabilityList = data.responseData;
          this.isProfitabilityList = true;
          this.isProfitabilityAPIHit = true;
          this.profitabilityLoadButton = false;
        }
        else{
          this.isProfitabilityList = false;
        }
      });
    }
    else{
      this.profitabilityLoadButton = false;
    }
    
  }

  getRemark() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    console.log("zrmId: " + zrmId + " rmId=" + rmId + "&region=" + region)

    this.customhttpService.getDataWithAuthentication("/Order/GetRemarkDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          //debugger;
          this.remarkList = data.responseData;
          this.isRemarkList = true;
        }
        else{
          this.isRemarkList = false;
        }
      });
  }

  saveRemark() {
    //debugger;
    if (this.redAccRemark != "" && this.redAccDueDate != null) {

      var selectedUser = this.assignedToUserList.filter(item => item.email == this.assignedToUserMail);
      const dueDate = new Date(this.redAccDueDate.year, this.redAccDueDate.month - 1, this.redAccDueDate.day + 1);
      this.customhttpService.postData("/account/UpdateRedAccountRemark",
        {
          "AccountId": this.selectedRedAccountId,
          "Remark": this.redAccRemark,
          "RemarkBy": this.loggedinUserId,
          "DueDate": dueDate,
          "AssignedToUserId": selectedUser[0].id,
          "AssignedToUser": selectedUser[0].name,
          "AssignedToUserMail": this.assignedToUserMail,
          "CustomerId": this.selectedRedCustomerId,
          "RemarkByName": this.loggedInUserName
        }).subscribe(data => {
          if (data.isSuccess) {

            this.redAccountRemarkList = [];
            this.getRedAccountRemarkList();
            this.getRedAccountWatchListNumbers();

          }
        });
      this.redAccRemark = "";
    }
  }

  GetRedAccountBase() {
    debugger;
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    console.log("zrmId: " + zrmId + " rmId=" + rmId + "&region=" + region)

    this.customhttpService.getDataWithAuthentication("/SR/GetRedAccountBaseDashboard?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          debugger;
          this.redAccountBaseList = data.responseData;
          this.isRedAccountBaseList = true;
        }
        else{
          this.isRedAccountBaseList = false;
        }
      });
  }
  getAssignedUserName(assignedUserMail) {
    //debugger;
    if (assignedUserMail != null) {
      var selectedUser = this.assignedToUserList.filter(item => item.email == assignedUserMail);

      return selectedUser[0].name;
    }
    else return "";
  }
  scrollToElement(el): void {
    //debugger;
    this.myScrollContainer.nativeElement.scroll({
      top: this.myScrollContainer.nativeElement.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  getRedAccountRemarkList() {
    //debugger;
    this.customhttpService.getDataWithAuthentication("/Account/GetAllRedAccountRemark?accountId=" + this.selectedRedAccountId).subscribe(data => {
      console.log(data);
      if (data.isSuccess) {
        this.redAccountRemarkList = data.responseData;
      }
    });
  }
  openRedAccRemark(content, item: any) {
    this.redAccountRemarkList = [];
    this.redAccRemark = "";
    this.assignedToUserMail = null;
    this.redAccDueDate = null;

    console.log("openRedAccRemark", item.accountId);
    this.selectedRedAccountId = item.accountId;
    this.selectedRedAccount = item.customerName;
    this.selectedRedCustomerId = item.customerId
    this.getRedAccountRemarkList();


    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: "myCustomModalClass" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.scrollToBottom();

  }
  openAccount(content, item: any) {
    console.log("Model" + item.accountId);
    this.selectedWatchListAccount = item;
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService.getDataWithAuthentication("/Order/GetOrderDetailForRedAccount?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&accountId=" + item.accountId).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          this.redAccountList = data.responseData;
        }
      });

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  category: string = "";
  categoryDefination = "";

  open(content, category) {
    this.category = category;
    this.createHtmlContent(category);
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createHtmlContent(category) {
    if (category == "SVBase") {
      this.categoryDefination = '<h4>SV Base is defined as all individual account orders which are claimed, with (CMS,RMS,FMS,MMS,SRS,LMS) services, and accounts are Tagged to RM or Order Created By (Sales,Call Center Team Lead,Call Center Agent,Call Center Manager)profile and order tenure >270 days.</h4><ul><li><h5>Opening </h5> The batch runs on 1st of very month and it will have all orders with "To Be Renewed" status</li><li><h5>Adjustments</h5> in case of chnages in rm tagging on accounts or other exceptions, this will adjust the opening numbers after 1st.</li><li><h5>Lost </h5> The batch will run everyday and it will have all orders with "Lost" status</li><li><h5>New </h5> The batch will run everyday and it will have all orders with order creation date in this month and oldordernumber is not present, i.e. new order, not renewal or exended.</li><li><h5>Closing </h5> The batch runs every day and it will have all orders excpet the one in lost status, orders with all other status modified in current month</li></ul>'
    }
  }

  filterAccountWatchList() {
    this.getRedAccountWatchListNumbers();  
  }
  sortAccountWatchList() {
    //debugger;
    var columnSort = "";
    var columnisAsc: boolean = false;
    var columnSortThenBy = "";
    var columnThenByisAsc: boolean = false;
    if (this.selectedSortForAccountWatchList == "Age") {
      columnSort = 'age';
    } else if (this.selectedSortForAccountWatchList == "CV") {
      columnSort = 'cvValue';
    } else if (this.selectedSortForAccountWatchList == "Red") {
      columnSort = 'redIndexStatus';
    }
    else if (this.selectedSortForAccountWatchList == "Remark") {
      columnSort = 'remarkDateTimeString';
      this.selectedSortthenByForAccountWatchList = 'None';
      columnisAsc = false;
    }
    else {
      columnSort = 'serviceCenter';
      columnisAsc = true;
    }

    if (this.selectedSortthenByForAccountWatchList == "Age") {
      columnSortThenBy = 'age';
    } else if (this.selectedSortthenByForAccountWatchList == "CV") {
      columnSortThenBy = 'cvValue';
    } else if (this.selectedSortthenByForAccountWatchList == "Red") {
      columnSortThenBy = 'redIndexStatus';
    } else {
      columnSortThenBy = 'serviceCenter';
      columnThenByisAsc = true;
    }
    this.commonSort(this.watchListNumbers.redAccountList, columnSort, columnisAsc, columnSortThenBy, columnThenByisAsc);
  }

  commonSort(listItems: any[], column: string, isASc: boolean, thenByColumn: string, thenByIsASc: boolean) {
    if (column == 'RemarkDateTimeString') {

      listItems.sort(function (a, b) {
        return ((new Date(a[column]) > new Date(b[column]) ? 1 : (new Date(a[column]) < new Date(b[column]) ? -1 : 0)))
      });

    }
    else {
      listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) || sort(a[thenByColumn], b[thenByColumn], thenByIsASc) });
    }
  }

  filterAgewiseWatchList() {

    this.getRedAccountWatchListNumbers();
  }

  onDateSelect(event) {
    //debugger;
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = day + "-" + month + "-" + year;
    return finalDate;
  }

  getAllRMList() {
    this.customhttpService.getDataWithAuthentication("/User/GetRMHeirarchy?userId=" + this.loggedinUserId + "&userTypeName=RM Admin").subscribe(data => {
      console.log(data);
      if (data.isSuccess) {

        this.assignedToUserList = data.responseData.rrmList;
        this.assignedToUserList = this.assignedToUserList.filter(item => item.name != "All RRM");

      }
    });
  }

  redirectToTickets() {
    var url = "tickets";
    window.open(url, "_blank");
  }
  redirectToMyTickets() {
    var url = "tickets_detail/0";
    window.open(url, "_blank");
  }
  redirectToUploadFile() {
    var url = "uploadfile";
    window.open(url, "_blank");
  }
  redirectToDocumentUploader() {
    var url = "documentuploader";
    window.open(url, "_blank");
  }

  redirectToRMMapping(){
    var url = "rm_mapping";
    window.open(url, "_blank");
  }

  redirectToOnBoardingRM(){
    var url = "onboarding_rm";
    window.open(url, "_blank");
  }

  GetNotificationOfOnBoardingRM(rmName){
    this.customhttpService.getDataWithAuthentication("/Account/GetNotificationOfOnBoardingRM?rmName=" + rmName).subscribe(data => {
      debugger;
      console.log("OnBoardingRMNotification", data);
      if(data.isSuccess){
        this.onBoardingNotificationCount = data.responseData;
      }
      else{
        this.onBoardingNotificationCount = 0;
      }
    })
  }

  openProfitabilityUpload(content){
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' ,windowClass: "myProfitabilityUploadModalClass" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  uploadStateWiseWagesFile(event) {
    debugger;
    this.uploadedWagesFile = event.target.files[0];
    document.getElementById("fileName").innerHTML = this.uploadedWagesFile.name;
  }

  uploadOnsiteResourceSalaryFile(event) {
    debugger;
    this.uploadedSalaryFile = event.target.files[0];
    document.getElementById("fileName1").innerHTML = this.uploadedSalaryFile.name;
  }

  uploadExcel(fileType: string) {
    debugger;
    if(fileType == "Wages"){
      if (this.uploadedWagesFile == null) {
        alert("Please upload Excel file!");
      }
      const formData = new FormData();
      formData.append('file', this.uploadedWagesFile, this.uploadedWagesFile.name);
      this.customhttpService.postData("/account/UploadStateWiseWages", formData).subscribe(data => {
        debugger;
        var response = data;
        if (response.isSuccess) {
          this.isWagesExcelUploaded = true;
          document.getElementById("fileName").innerHTML = "Choose Excel File";
          this.uploadedWagesFile = null;
          alert(response.responseData);
          this.closeModal();
        }
        else {
          alert(response.responseData);
        }
      }, error => {
        alert("Something Went Wrong!");
        document.getElementById("fileName").innerHTML = "Choose Excel File";
        this.uploadedWagesFile = null;
        console.log(error);
      })
    }

    if(fileType == "Salary"){
      if (this.uploadedSalaryFile == null) {
        alert("Please upload Excel file!");
      }
      const formData = new FormData();
      formData.append('file', this.uploadedSalaryFile, this.uploadedSalaryFile.name);
      this.customhttpService.postData("/account/UploadOnsiteResourceSalary", formData).subscribe(data => {
        debugger;
        var response = data;
        if (response.isSuccess) {
          this.isSalaryExcelUploaded = true;
          document.getElementById("fileName1").innerHTML = "Choose Excel File";
          this.uploadedSalaryFile = null;
          alert(response.responseData);
          this.closeModal();
        }
        else {
          alert(response.responseData);
        }
      }, error => {
        alert("Something Went Wrong!");
        document.getElementById("fileName1").innerHTML = "Choose Excel File";
          this.uploadedSalaryFile = null;
        console.log(error);
      })
    }
    
  }

  closeModal() {
    this.uploadedWagesFile = null;
    this.uploadedSalaryFile = null;
    this.modalService.dismissAll();
  }





}



function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}
