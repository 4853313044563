import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss']
})
export class GoogleAuthComponent implements OnInit {

  googleCode: string = "";
  userInfo:any={};

  constructor(private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private router:Router) { 
    this.googleCode= this.activatedRoute.snapshot.queryParamMap.get('code');
  }

  ngOnInit() {
    if(this.googleCode !=undefined ||this.googleCode !=null||this.googleCode !=""){
      this.validateGoogleUserByCode();
      }
  }

  validateGoogleUserByCode(){
    this.customHttpService.getData("/user/ValidateGoogleUserByCode?code="+this.googleCode)
    .subscribe(data=>{
      if(data.isSuccess){
        localStorage.setItem("loggedInMobileNumber",data.responseData.mobile);
        localStorage.setItem("gcbstoretoken",data.responseData.accessToken);
        localStorage.setItem("loggedinemail",data.responseData.email);
        localStorage.setItem("loggedinusername",data.responseData.name);
        localStorage.setItem("loggedinuserid",data.responseData.id);
        localStorage.setItem("loggedinusertype",data.responseData.typeName);

        this.router.navigate(["dashboard"]);
      }
      else{
        debugger;
        alert(data.errorMessage);
      }
     
    });
  }
}
