import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import {  ActivatedRoute } from '@angular/router';
import {  CommonMethods } from 'src/app/services/commonmethods';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  customerFileList=[];
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems: Array<any>;
  userType: string = '';
  sortText: string;
  reverse: boolean = false;
  searchText="";
  customerId:string="";
  customerFile:any;
  fileName:string="";
  isSave: boolean = false;
  loggedinUserId:string;
  loggedInUserName:string;  
  isList:boolean=true;
  isDelete=false;
  fileArry=[];
  closeResult = '';
  errorMsg: string;
  successMsg: string;
  isError:boolean=true;
  fileType:string="Audit";
  remark:string="";
  fileTitle:string="";
  isSuccess:boolean=false;
  toBeDeleteId:number=0;
  tobeDeleteCustomer:string="";
  tobeDeleteTitle:string="";
  tobeDeleteFile: string = "";
  tobeDeleteFileUrl: string = "";
  tobeDeleteType: string = "";
  isCallSave: boolean = false;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService, private commonMethods: CommonMethods,
     private modalService: NgbModal) { }

  ngOnInit() {    
    this.loggedInUserName = localStorage.getItem("loggedinusername");
    this.loggedinUserId = localStorage.getItem("loggedinemail");

    this.getCustomerFilesList();
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  getCustomerFilesList(){
    this.isList=false;
 this.customHttpService.getDataWithAuthentication("/account/GetAllCustomerFiles")
      .subscribe(data => {
        console.log(data.responseData);
        this.customerFileList = data.responseData;
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList=true;
        //this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, null, 'createdOnDisplayDate', false,'date');
        this.sortText = "Date (Descending)";

      });
  }
  readURL(event): void {
    debugger;
    console.warn(event.target.files);
    if (event.target.files && event.target.files[0]) {
      if(event.target.files[0].size>(5000 * 1024)){
        this.errorMsg="File size exceeds the maximum limit 5MB";
        this.isError=true;
        document.getElementById("fileName").innerHTML="Choose file";
        this.fileName="";
      }
      else{
        this.errorMsg="";
        this.isError=false;
        this.fileArry=event.target.files;
        console.log("size"+event.target.files[0].size);
       
        this.customerFile=event.target.files[0];
        this.fileName=event.target.files[0].name;
        document.getElementById("fileName").innerHTML=event.target.files[0].name;
      }
     
    }
  }
  checkLoading() {   
    if (this.items != undefined && this.items != null && (this.items.length > 0|| this.isList)) {
      return true;
    }
    else {
      return false;
    }
  }
  search(value: string): void {
    this.isList=false;
    if (value.length >= 3) {
      this.pageOfItems = this.allPageOfItems.filter((val) => val.customerId.toLowerCase().includes(value.toLowerCase()) ||
        (val.fileName != null && val.fileName.toLowerCase().includes(value.toLowerCase())) ||
        (val.createdOnDisplayDate != null && val.createdOnDisplayDate.toLowerCase().includes(value.toLowerCase()))||
        (val.fileTitle != null && val.fileTitle.toLowerCase().includes(value.toLowerCase()))||
        (val.remark != null && val.remark.toLowerCase().includes(value.toLowerCase()))||
        // (val.createdBy != null && val.createdBy.toLowerCase().includes(value.toLowerCase()))
        (val.fileSource != null && val.fileSource.toLowerCase().includes(value.toLowerCase())) ||
        (val.fileType != null && val.fileType.toLowerCase().includes(value.toLowerCase()))  
        )       
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }

    this.items = this.pageOfItems;
    this.isList=true;

}
  isUploadEnable(){
    console.log(this.fileName);
    if (this.isCallSave) {
      return true;
    }
    if(this.fileType=="Common"){
if(this.fileName!=null && this.fileName!=undefined && this.fileName!="" ){
      return false;
    }
    else return true;
  }
    if(this.customerId!=null && this.customerId!=undefined && this.customerId!="" && 
    this.fileName!=null && this.fileName!=undefined && this.fileName!="" ){
      return false;

    }
    else return true;
  }
  navigateFile(fileUrl:string){

    window.open(fileUrl,"_blank");
  }
  uploadFile(){    
    const formData=new FormData();

    var domainUrl= this.customHttpService.getDomainUrl();
    formData.append('file',this.customerFile,this.customerFile.name);
    formData.append('customerId',this.customerId);
    formData.append('fileLocation', domainUrl);
    formData.append('fileType',this.fileType);
    formData.append('fileTitle',this.fileTitle);
    formData.append('remark',this.remark);
    formData.append('userId',this.loggedinUserId);
    formData.append('userName',this.loggedInUserName);  
    formData.append('fileSource',"RMDashboard");  

    this.isCallSave = true;
    this.customHttpService.postData('/account/SaveCustomerFile',formData).subscribe(data=>{
      if(data.isSuccess){
        this.isSuccess = true;
        this.getCustomerFilesList();  
        this.customerId="";
        this.fileTitle="";
        this.remark = "";
        this.isCallSave = false;
        this.successMsg="File Uploaded Successfully!";
        document.getElementById("fileName").innerHTML="Choose file";
        this.fileName="";
        setTimeout(() => { document.getElementById("btnCloseModel").click() }, 3000);

      }
      else{
        this.isError = true;
        this.errorMsg="There is some error while processing";
      }
        

      });    
  }
  deleteRow(){
    this.customHttpService.postData('/account/DeleteCustomerFile', {
      "Id": this.toBeDeleteId,
      "FileUrl": this.tobeDeleteFileUrl,
      "FileName": this.tobeDeleteFile
    }).subscribe(data=>{
      if(data.isSuccess){
        this.isDelete = true;
        this.getCustomerFilesList();  
        document.getElementById("btnNoModel").click()
        setTimeout(() => { this.isDelete = false; }, 5000);
      }

      });  

  }

  openFileUpload(content, item: any) {
    debugger;
    this.isError=false;
    this.errorMsg ="";
    this.successMsg="";
    this.customerId="";
    this.fileTitle="";
    this.remark="";
    this.isSuccess=false;
    this.fileType = "Audit";
    this.isCallSave = false;
    this.fileName = "";
    // document.getElementById("fileName").innerHTML="Choose file";
    // this.fileName="";
   
    console.log("type" + this.userType);
    this.modalService.open(content, { windowClass: 'my-class', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openConfirmation(content, item: any) {
    debugger;
   
   this.toBeDeleteId=item.id;
   this.tobeDeleteCustomer=item.customerId;
   this.tobeDeleteTitle=item.fileTitle;
    this.tobeDeleteFile = item.fileName;
    this.tobeDeleteFileUrl = item.fileUrl;
   this.tobeDeleteType=item.fileType;

    this.modalService.open(content, {windowClass: 'my-classConfirm' ,ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if (item.classList != undefined) {
        item.classList.remove("selectBackColor");
      }
    });

    parent.classList.add("selectBackColor");

    var sortResponse = this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, event, column, isASc, dataTyp);
    this.sortText = sortResponse.sortText;
    this.reverse = sortResponse.isReverse;

    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  checkFileType(){
    if(this.fileType=="Common"){
      this.customerId="";
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
