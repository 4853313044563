import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComplaintComponent } from './data-tables/complaint/complaint.component';
import { InvoicesComponent } from './data-tables/invoices/invoices.component';
import { RedaccountComponent } from './data-tables/redaccount/redaccount.component';
import { SrbaseComponent } from './data-tables/srbase/srbase.component';
import { LoginComponent } from './login/login.component';
import { RbtDeliveryComponent } from './data-tables/rbt-delivery/rbt-delivery.component';
import{FarmingOrderComponent} from './data-tables/farming-order/farming-order.component';
import { RenewalOrderComponent } from './data-tables/renewal-order/renewal-order.component';
import { AccountSummaryComponent } from './data-tables/account-summary/account-summary.component';
import { TicketsComponent } from './data-tables/tickets/tickets.component';
import { TicketsDetailComponent } from './data-tables/tickets-detail/tickets-detail.component';
import { TicketsInteractionDetailComponent } from './data-tables/tickets-interaction-detail/tickets-interaction-detail.component';
import { UploadFileComponent } from './data-tables/upload-file/upload-file.component';
import { DocumentUploaderComponent} from './data-tables/document-uploader/document-uploader.component';
import { DocumentMappingComponent } from './data-tables/document-mapping/document-mapping.component';

import { S3loginComponent } from './s3login/s3login.component';
import { RMMappingComponent} from './rm-mapping/rm-mapping.component';
import { PriceEscalationComponent } from './data-tables/price-escalation/price-escalation.component';
import { ProfitabilityComponent } from './data-tables/profitability/profitability.component';
import { OnBoardingRMComponent } from './data-tables/onboarding-rm/onboarding-rm.component'; 
import { GoogleAuthComponent} from './googleAuthLogin/google-auth/google-auth.component';

const routes: Routes = [

 { path: 'login', component:LoginComponent, pathMatch:'full' }, //normal login
 //{ path: '', redirectTo: 'login', pathMatch: 'full' }, //normal login
 { path: '', redirectTo: 'dashboard', pathMatch: 'full' },//for one login
 //{ path: 'login', redirectTo: 'dashboard' }, //for one login

 
 //{path:'',component:LoginComponent},
  
  { path: 'dashboard', component: DashboardComponent, pathMatch:'full' },
  { path: 'complaint/:segmentType/:period/:status', component: ComplaintComponent, pathMatch:'full' },
  { path: 'SVBase/:period/:status', component: SrbaseComponent, pathMatch:'full' },
  { path: 'RedAccount/:period/:category', component: RedaccountComponent, pathMatch:'full' },
  { path: 'Invoices/:period/:category', component: InvoicesComponent, pathMatch: 'full' },
  { path: 'DeliveryRBT/:period/:status', component: RbtDeliveryComponent, pathMatch: 'full' },
  { path: 'FarmingOrder/:period/:status', component: FarmingOrderComponent, pathMatch: 'full' },
  { path: 'RenewalOrder/:period/:category', component: RenewalOrderComponent, pathMatch: 'full' },
  { path: 'RenewalOrder/:period/:renewalType/:category/:year', component: RenewalOrderComponent, pathMatch: 'full' },
  { path: 'AccountSummary/:healthType', component: AccountSummaryComponent, pathMatch: 'full' },
  {path:'tickets',component:TicketsComponent,pathMatch:'full'},
  {path:'tickets_detail/:accountId',component:TicketsDetailComponent,pathMatch:'full'},
  {path:'tickets_interaction_detail/:ticketId',component:TicketsInteractionDetailComponent,pathMatch:'full'},
  {path:'uploadfile',component:UploadFileComponent,pathMatch:'full'},
  {path: 'documentuploader', component:DocumentUploaderComponent,pathMatch:'full'},

  {path: 'document_mapping', component:DocumentMappingComponent, pathMatch: 'full'},
  {path: 's3login/:id', component:S3loginComponent, pathMatch: 'full'}, 
  {path: 'rm_mapping', component:RMMappingComponent, pathMatch: 'full'},
  {path: 'PriceEscalation/:period', component:PriceEscalationComponent, pathMatch: 'full'},
  {path: 'Profitability/:period', component:ProfitabilityComponent, pathMatch: 'full'},
  {path: 's3login', component:S3loginComponent, pathMatch: 'full'},
  {path: 'onboarding_rm', component:OnBoardingRMComponent, pathMatch: 'full'},
  //{ path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  {path: 'google-auth', component:GoogleAuthComponent, pathMatch: 'full'},  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
