import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { CommonMethods } from 'src/app/services/commonmethods';
import {formatDate } from '@angular/common';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter} from 'src/app/services/dateformat';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
   ]
})
export class InvoicesComponent implements OnInit {

  category:string="";
  period : string="";
  invoiceList:any=[];
  selectedZRMName:string="";
  selectedRMName:string="";
  selectedRegion :string="";
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems:Array<any>;
  totAmt:number=0;
  searchText:string="";
  sortText2: string = "";
  sortText3: string = "";
  reverse: boolean = false;
  exportData:boolean=true;
  isList:boolean=true;
  sortText:string="";
  loggedinUserId:string="";
  isSave:boolean=false;
  minPickerDate:NgbDate;
  activeIdString: any ;
  siteInvoiceList: any = [];
  parentInvoiceList: any = [];
  parentPageOfItems: Array<any>;
  sitePageOfItems: Array<any>;
  invoiceRemarkList = [];
  isAllSave: boolean = false;
  newData: any[] = [];

  constructor(private httpClient: HttpClient, private activatedRoute:ActivatedRoute,
    private customHttpService:CustomhttpService, private commonMethods: CommonMethods) { 
  this.category=  this.activatedRoute.snapshot.params.category;
  this.period=  this.activatedRoute.snapshot.params.period;
  
  }
  roundNumber(no:any){
    return Math.round(no); //parseFloat(no).toFixed(2);
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}
  ngOnInit(): void {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
this.selectedRMName = localStorage.getItem("SelectedRMName");
this.selectedRegion = localStorage.getItem("SelectedRegion");
this.loggedinUserId = localStorage.getItem("loggedinuserid");
this.invoiceRemarkList = this.commonMethods.invoiceRemarks;
this.minPickerDate=new NgbDate(new Date().getFullYear(),new Date().getMonth()+1,new Date().getDate()+1);
 
    this.getRedAccountList();
  }
  checkLoading(){
    if(this.items!=undefined && this.items!=null  && (this.items.length>0 || this.isList)&& this.exportData )
    {return true;
    }
    else
    {
      return false;
    }
    }
  getRedAccountList(){
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.isList=false;
this.customHttpService.getDataWithAuthentication("/Invoice/GetInvoiceList?zrmId="+
zrmId+"&rmId="+rmId+"&region="+region+"&category="+this.category+"&period="+this.period).subscribe(data=>{
  console.log(data);
  if(data.isSuccess)
  {
    console.log(data);
    this.invoiceList = data.responseData.invoiceWiseModel;
    this.siteInvoiceList = data.responseData.siteWiseModel;
    this.parentInvoiceList = data.responseData.parentWiseModel;
    this.sitePageOfItems = data.responseData.siteWiseModel;
    this.parentPageOfItems = data.responseData.parentWiseModel;
debugger;
    for (let i =0;i<= this.invoiceList - 1;  i++) {
      
      if(this.invoiceList[i].collectionDate!=null){
        
      var value = new NgbDate(parseInt( this.invoiceList[i].collectionDate.toString().substring(0,4)),
      parseInt( this.invoiceList[i].collectionDate.toString().substring(5,7) ),
      parseInt(this.invoiceList[i].collectionDate.toString().substring(8,10)));

      this.invoiceList[i].collectionDate=value;
    }
    }

    
    this.items = this.invoiceList;
    this.allPageOfItems= this.invoiceList;
    this.isList=true;
    if(this.category=="Amount"){
    this.totAmt= this.items.reduce(function(prev,cur){
      return prev+cur.outstandingAmount+cur.outstandingAmountLessThan60Days+cur.outstandingBetween60And90Days+ cur.outstandingGreaterThan90Days;
    },0);
  }
  else{
    this.totAmt= this.items.length;
  }
  

  this.commonSort(this.items,this.pageOfItems,"accountName",true);   
      this.sortText="Account Name (Ascending)";  

      this.commonSort(this.siteInvoiceList, this.sitePageOfItems, 'accountName', true);
          this.sortText2 = "Account Name (Ascending)";

          this.commonSort(this.parentInvoiceList, this.parentPageOfItems, 'parentAccountName', true);
          this.sortText3 = "Account Name (Ascending)";

  }
});
}


onDateSelect(event) {
  debugger;
  let year = event.year;
  let month = event.month <= 9 ? '0' + event.month : event.month;;
  let day = event.day <= 9 ? '0' + event.day : event.day;;
  let finalDate =day+ "-" + month + "-" + year;
  return finalDate;
 }

 
saveRemark(item){  
  debugger;
   
   //const collDate=new Date(item.collectionDate.year,item.collectionDate.month-1,item.collectionDate.day+1);
   debugger;
    this.customHttpService.postData("/Invoice/UpdateInvoiceRemark" ,
    {
      "InvoiceNo":item.invoiceNo,
      "AccountNo":item.accountNo,
      "Remark":item.remark,
      //"CollectionDate":collDate,
      "UserId":this.loggedinUserId
    }).subscribe(data => {
      if (data.isSuccess) {
      this.isSave = true;
      setTimeout(() => { this.isSave = false; }, 5000);
      }
    });
  }

  onDescriptionChange(item: any){
    debugger;
    item.isChange = true;
  }

  saveAllRemark(){
    debugger;
    this.isAllSave = true;
    this.newData = this.pageOfItems.filter(a => a.isChange == true).map((item)=> (
      {
        "InvoiceNo":item.invoiceNo,
        "AccountNo":item.accountNo,
        "Remark":item.remark,
        "UserId":this.loggedinUserId
      }
    ) );
    //console.log("UpdatedData", this.newData);

    if(this.newData != null && this.newData.length > 0){
      this.customHttpService.postData("/Invoice/SaveAllInvoiceRemark" ,this.newData).subscribe(data => {
        if (data.isSuccess) {
        this.isSave = true;
        setTimeout(() => { this.isSave = false; }, 5000);
        }
        else{
          alert("Something went wrong!");
        }
      });
    }
    else{
      this.isAllSave = false;
      alert("No Data Updated.");
    }
    
  }

search(value:string):void{
  debugger;
  this.isList=false;
 if(value.length>=3){
   console.log(value);
   if(this.category=='Amount'){
   this.pageOfItems= this.allPageOfItems.filter((val)=>(val.regionName!=null && val.regionName.toLowerCase().includes(value.toLowerCase()))||
   (val.scName !=null && val.scName.toLowerCase().includes(value.toLowerCase()))||   
   (val.accountName!=null && val.accountName.toLowerCase().includes(value.toLowerCase()))||
   (val.accountNo!=null && val.accountNo.toLowerCase().includes(value.toLowerCase()))||
   (val.parentAccountNo!=null && val.parentAccountNo.toLowerCase().includes(value.toLowerCase()))||
   (val.parentAccountName!=null && val.parentAccountName.toLowerCase().includes(value.toLowerCase()))||
    (val.outstandingAmount !=null && val.outstandingAmount.toString().includes(value))||
    (val.outstandingAmountLessThan60Days !=null && val.outstandingAmountLessThan60Days.toString().includes(value))||
    (val.outstandingBetween60And90Days !=null && val.outstandingBetween60And90Days.toString().includes(value))||
    (val.outstandingGreaterThan90Days !=null && val.outstandingGreaterThan90Days.toString().includes(value))||
   (val.invoiceNo!=null && val.invoiceNo.toLowerCase().includes(value.toLowerCase()))||
   (val.remark!=null && val.remark.toLowerCase().includes(value.toLowerCase()))||
   (val.rmmName!=null && val.rmmName.toLowerCase().includes(value.toLowerCase()))||
   (val.collectionDate!=null && val.collectionDate.toString().toLowerCase().includes(value.toLowerCase()))||
    (val.invoiceDateDisplayValue !=null && val.invoiceDateDisplayValue.toLowerCase().includes(value.toLowerCase())));    
   }
   else{
    this.pageOfItems= this.allPageOfItems;
    this.pageOfItems= this.allPageOfItems.filter((val)=>val.regionName.toLowerCase().includes(value.toLowerCase())||
    val.scName.toLowerCase().includes(value.toLowerCase())||   
    val.accountName.toLowerCase().includes(value.toLowerCase())||
    val.accountNo.toLowerCase().includes(value.toLowerCase())||
    val.outstandingAmount.toString().includes(value)||
    val.totalOutstandingAmount.toString().includes(value)||
    val.outstandingAmountLessThan60Days.toString().includes(value)||
    val.outstandingGreaterThan90Days.toString().includes(value)||
    val.outstandingGreaterThan90Days.toString().includes(value));  
   }
 }
 else{
   this.pageOfItems= this.allPageOfItems;
 }

 if(this.siteInvoiceList.length > 0){
  debugger;
  if(value.length>=3){
    this.siteInvoiceList = this.sitePageOfItems.filter((val)=>val.regionName.toLowerCase().includes(value.toLowerCase())||
    val.scName.toLowerCase().includes(value.toLowerCase())||   
    val.accountName.toLowerCase().includes(value.toLowerCase())||
    val.accountNo.toLowerCase().includes(value.toLowerCase())||
    val.totalOutstandingAmount.toString().includes(value)||
    val.outstandingAmountLessThan60Days.toString().includes(value)||
    val.outstandingGreaterThan90Days.toString().includes(value)||
    val.outstandingGreaterThan90Days.toString().includes(value));   
  }
  else{
    this.siteInvoiceList = this.sitePageOfItems;
  }
 }
 else{
  this.siteInvoiceList = this.sitePageOfItems;
 }

if(this.parentInvoiceList.length > 0){
  debugger;
  if(value.length >= 3){
    this.parentInvoiceList = this.parentPageOfItems.filter((val)=>val.parentAccountNo.toLowerCase().includes(value.toLowerCase())||
    val.parentAccountName.toLowerCase().includes(value.toLowerCase())||
    val.totalOutstandingAmount.toString().includes(value)||
    val.outstandingAmountLessThan60Days.toString().includes(value)||
    val.outstandingGreaterThan90Days.toString().includes(value)||
    val.outstandingGreaterThan90Days.toString().includes(value)); 
  }
  else{
    this.parentInvoiceList = this.parentPageOfItems;
  }
}
else{
  this.parentInvoiceList = this.parentPageOfItems;
}

 this.items=this.pageOfItems;
 this.isList=true;
}


sortCol(column: string, isASc: boolean, listType: string, event, dataTyp?: any): void {
  debugger;
  //sortCol(column: string): void {
  console.log("sort" + isASc);

  var target = event.target;
  var parent = target.parentElement;
  console.log(target);
  console.log(target.parentElement);
  var colName = "";

  console.log(event.srcElement.nodeName);
  if (event.srcElement.nodeName == 'SPAN') {
    var index = event.srcElement.innerHTML.indexOf("<i");
    colName = event.srcElement.innerHTML.substr(0, index);
    console.log(colName);
  }
  var sortedBy;
  if (isASc) {
    sortedBy = "Ascending";
  }
  else {
    sortedBy = "Descending";
  }

  parent.parentElement.classList.remove("selectBackColor");
  parent.parentElement.childNodes.forEach(function (item) {
    if (item.classList != undefined)
      item.classList.remove("selectBackColor");
  });

  parent.classList.add("selectBackColor");

  if (listType == 'detail') {
    this.commonSort(this.items, this.pageOfItems, column, isASc, dataTyp);
    this.sortText = colName + " (" + sortedBy + ")";
  }
  else if (listType == "summary") {
    this.commonSort(this.siteInvoiceList, this.sitePageOfItems, column, isASc, dataTyp);
    this.sortText2 = colName + " (" + sortedBy + ")";
  }
  else {
    this.commonSort(this.parentInvoiceList, this.parentPageOfItems, column, isASc, dataTyp);
    this.sortText3 = colName + " (" + sortedBy + ")";
  }
}

commonSort(listItems :any[],pageOfItem:Array<any>, column: string, isASc: boolean, dataTyp?: any){
  if (dataTyp != "date") {
    listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
    this.reverse = !this.reverse;
  }
  else {
    if (isASc == true) {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

    }
    else {
      pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

    }
    this.reverse = !this.reverse
    listItems = pageOfItem;
  }
  this.onChangePage(listItems.slice(0, 20));
}

setActiveIndex(evt: any) {
  console.log("set index" + evt.nextId);
  this.activeIdString = evt.nextId;
}

exportToExcel(){
  var zrmId = localStorage.getItem("SelectedZRMId");
  var rmId = localStorage.getItem("SelectedRMId");
  var region = localStorage.getItem("SelectedRegion");

  var excelTyp;
  if (this.activeIdString == "1") {
    excelTyp = "Detail";
  }
  if (this.activeIdString == "2") {
    excelTyp = "SiteWise";
  }
  if (this.activeIdString == "3") {
    excelTyp = "AccountWise";
  }

this.exportData=false;
  this.customHttpService.exportDataWithAuthentication("/Invoice/ExportInvoiceList?zrmId="+
  zrmId+"&rmId="+rmId+"&region="+region+"&category="+excelTyp+"&period="+this.period)
  .subscribe(data=>{
    console.log(data);
    this.exportData=true;
    let b: any = new Blob([data], {type: 'application/vnd.ms-excel' });
    var url = window.URL.createObjectURL(b);
    //window.open(url);
    var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
    var anchor = document.createElement("a");
    anchor.download = "Invoice_" +excelTyp+"_" +this.period+"_"+nowDate+".xls";
    anchor.href = url;
    anchor.click();
  });
}

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}