import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-s3login',
  templateUrl: './s3login.component.html',
  styleUrls: ['./s3login.component.scss']
})
export class S3loginComponent implements OnInit {
  userId:string="";
  sfdcCode:string="";

  constructor(private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private router:Router) {
   debugger;
    this.userId = this.activatedRoute.snapshot.params.id;
    this.sfdcCode= this.activatedRoute.snapshot.queryParamMap.get('code');
   }

  ngOnInit() {
    debugger;
    if(this.sfdcCode==undefined ||this.sfdcCode==null||this.sfdcCode==""){
    this.getUserById();
    }
    else{
     this.validateSFDCUserByCode();
    }
  }
  getUserById(){
    debugger;
    this.customHttpService.getData("/user/GetUserById?refId="+this.userId)
    .subscribe(data=>{
      if(data.isSuccess){
        localStorage.setItem("loggedInMobileNumber",data.responseData.mobile);
       localStorage.setItem("gcbstoretoken",data.responseData.accessToken);
       localStorage.setItem("loggedinemail",data.responseData.email);
       localStorage.setItem("loggedinusername",data.responseData.name);
       localStorage.setItem("loggedinuserid",data.responseData.id);
       localStorage.setItem("loggedinusertype",data.responseData.typeName);
      
        this.router.navigate(["dashboard"]);
      }
     
    });
  }

  validateSFDCUserByCode(){
    debugger;
    this.customHttpService.getData("/user/ValidateSFDCUserByCode?code="+this.sfdcCode)
    .subscribe(data=>{
      debugger;
      if(data.isSuccess){
       localStorage.setItem("loggedInMobileNumber",data.responseData.mobile);
       localStorage.setItem("gcbstoretoken",data.responseData.accessToken);
       localStorage.setItem("loggedinemail",data.responseData.email);
       localStorage.setItem("loggedinusername",data.responseData.name);
       localStorage.setItem("loggedinuserid",data.responseData.id);
       localStorage.setItem("loggedinusertype",data.responseData.typeName);
      
        this.router.navigate(["dashboard"]);
      }
      else{
        alert("No Access found for RM Insights, please contact IT Support Team.");
      }
     
    });
  }
}
