import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-onboarding-rm',
  templateUrl: './onboarding-rm.component.html',
  styleUrls: ['./onboarding-rm.component.scss']
})
export class OnBoardingRMComponent implements OnInit {
  closeResult = '';
  uploadedFile: any;
  showData: boolean = false;
  errorMessage: string = "";
  private modalRef: NgbModalRef;
  fileName: string;
  isOnBoardingExcelUploaded: boolean = false;
  onBoardingListOfRM = [];
  selectedZRMName: string = "";
  selectedRMName: string = "";
  selectedRegion: string = "";
  userType: string = "";
  loggedinUserId = "";
  loggedinUserType = "";
  exportData:boolean=true;
  isDisabledDownload: boolean = false;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private modalService: NgbModal) {



  }

  ngOnInit() {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.userType = localStorage.getItem("loggedinusertype");
    this.loggedinUserId = localStorage.getItem("loggedinuserid");
    this.loggedinUserType = localStorage.getItem("loggedinusertype");
    this.getAllOnBoardingRM(this.loggedinUserId);
  }

  open(content) {
    debugger;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: "custom-class", }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  getAllOnBoardingRM(rmName) {
    this.customHttpService.getDataWithAuthentication("/Account/GetAllOnBoardingRM?rmName=" + rmName).subscribe(data => {
      debugger;
      console.log("OnBoardingList", data);
      if (data.isSuccess) {
          this.onBoardingListOfRM = data.responseData;
      }
       else {
        this.onBoardingListOfRM = [];
       }
    })
  }


  uploadFile(event) {
    debugger;
    this.uploadedFile = event.target.files[0];
    document.getElementById("fileName").innerHTML = this.uploadedFile.name;
  }

  uploadExcel() {
    debugger;
    if (this.uploadedFile == null) {
      alert("Please upload Excel file!");
    }
    const formData = new FormData();
    formData.append('file', this.uploadedFile, this.uploadedFile.name);
    this.customHttpService.postData("/account/UploadOnboardingRM", formData).subscribe(data => {
      debugger;
      var response = data;
      if (response.isSuccess) {
        this.isOnBoardingExcelUploaded = true;
        document.getElementById("fileName").innerHTML = "Choose Excel File";
        this.uploadedFile = null;
        alert(response.responseData);
        this.getAllOnBoardingRM(this.loggedinUserId);
        this.closeModal();
      }
      else {
        alert(response.responseData);
      }
    }, error => {
      console.log(error);
    })
  }

  updateVisitStatus(item) {
    if (window.confirm('Are you sure you want to Mark Visit?')) {
      this.customHttpService.postData("/Account/UpdateOnboardingRMStatus",
        {
          "Id": item.id,
          "IsVisited": true,
          "UpdatedBy": this.loggedinUserId
        }).subscribe(data => {
          debugger;
          if (data.isSuccess) {
            this.getAllOnBoardingRM(this.loggedinUserId);
          }
        });
    }

  }

  download() {
    this.exportData = false;
    this.isDisabledDownload = true;
    this.customHttpService.exportDataWithAuthentication("/Account/DownLoadOnBoardingRMReport").subscribe(data => {
      this.exportData = true;
      this.isDisabledDownload = false;
      let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
      var url = window.URL.createObjectURL(b);
      var nowDate = formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
      var anchor = document.createElement("a");
      anchor.download = "OnBoarding_RM_Report_" + nowDate + ".xls";
      anchor.href = url;
      anchor.click();
    })
  }

  closeModal() {
    this.isOnBoardingExcelUploaded = false;
    this.uploadedFile = null;
    this.modalService.dismissAll();
  }


}
