import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { groupBy } from 'rxjs/operators';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-srbase',
  templateUrl: './srbase.component.html',
  styleUrls: ['./srbase.component.scss'],
  styles: ['.model-dialog{max-width:1000px !important;}']
})
export class SrbaseComponent implements OnInit {

  period: string = "";
  srStatus: string = "";
  srList: any = [];
  selectedZRMName: string = "";
  selectedRMName: string = "";
  selectedRegion: string = "";
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems: Array<any>;
  custPageOfItems: Array<any>;
  customerItems = [];
  totSV: number = 0;
  closeResult = '';
  modelCustList = [];
  isAllLoad: boolean = false;
  searchText: string = "";
  reverse: boolean = false;
  exportData: boolean = true;
  isList: boolean = true;
  isRemarkList: boolean = true;
  loggedinUserId: string = "";
  accountItem = [];
  accountPageOfItem: Array<any>;
  activeIdString: any = "2";
  sortText: string = "";
  sortText2: string = "";
  sortText3: string = "";
  reasonList = [];
  isRedAccount: boolean = false;
  isReasonHidden: boolean = true;
  isSave: boolean = false;
  isAllSave: boolean = false;
  remarkHistory = [];
  oldData: any[] = [];
  newData: any[] = [];

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private modalService: NgbModal) {
    this.period = this.activatedRoute.snapshot.params.period;
    this.srStatus = this.activatedRoute.snapshot.params.status;
  }

  open(content, modelId: any, accountNo: string) {
    debugger;
    console.log("Model" + modelId);
    console.log(this.srList);
    this.getSVBaseRemarkHistory(accountNo);

    this.modelCustList = this.srList.filter(s => s.accountId == modelId);
    console.log(this.modelCustList);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit(): void {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
    this.selectedRMName = localStorage.getItem("SelectedRMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("loggedinuserid");
    this.getSRList();
    this.reasonList = ['Service missed', 'Efficacy issue', 'Price reduction', 'Technician absenteeism', 'Outstanding payment', 'Lower quote from competitor', 'Site closure merger', 'Change of FM partner'];
  }

  

  getSRList() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    //this.items=null;
    this.isList = false;
    this.customHttpService.getDataWithAuthentication("/SR/GetSRList?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&period=" + this.period + "&srStatus=" + this.srStatus).subscribe(data => {

        if (data.isSuccess) {
          console.log(data);
          this.srList = data.responseData.detailModel;
          this.items = data.responseData.detailModel;
          console.log(this.items);
          this.isList = true;
          this.allPageOfItems = data.responseData.detailModel;
          // if(this.srStatus=="New"){
          this.customerItems = data.responseData.siteModel; //this.groupBy(this.srList).sort((a, b) => this.compareObjects(a, b, 'accountName') || this.compareObjects(a, b, 'accountId'));
          this.custPageOfItems = this.customerItems;

          this.accountItem = data.responseData.accountModel;
          this.accountPageOfItem = data.responseData.accountModel;

          //}
          this.totSV = this.items.reduce(function (prev, cur) {
            return prev + cur.cvValue;
          }, 0);
          this.isAllLoad = true;

          this.commonSort(this.items, this.pageOfItems, "accountName", true);
          this.sortText = "Account Name (Ascending)";

          this.commonSort(this.customerItems, this.custPageOfItems, "accountName", true);
          this.sortText2 = "Account Name (Ascending)";


          this.commonSort(this.accountItem, this.accountPageOfItem, "parentAccountName", true);
          this.sortText3 = "Account Name (Ascending)";
        }
      });

  }

  saveRedAccountRemark(item) {
    debugger;
    if (window.confirm('Are you sure you want to save?')) {
      if (item.isRedAccount == true  && item.redAccountReason != null && item.redAccountReason != "") {
        this.customHttpService.postData("/SR/SaveSVBaseRemark",
          {
            "AccountNo": item.accountNo,
            "OrderNo": item.orderNo,
            "IsRedAccount": item.isRedAccount,
            "RedAccountReason": item.redAccountReason,
            "Description": item.description,
            "UserId": this.loggedinUserId
          }).subscribe(data => {
            if (data.isSuccess) {
              alert("Red Account Remark Saved!");
              console.log(data);
              this.isSave = true;
              setTimeout(() => { this.isSave = false; }, 5000);
            }
          });
      }
      else if(item.isRedAccount == false){
        this.customHttpService.postData("/SR/SaveSVBaseRemark",
          {
            "AccountNo": item.accountNo,
            "OrderNo": item.orderNo,
            "IsRedAccount": item.isRedAccount,
            "RedAccountReason": item.redAccountReason,
            "Description": item.description,
            "UserId": this.loggedinUserId
          }).subscribe(data => {
            if (data.isSuccess) {
              alert("Red Account Remark Saved!");
              console.log(data);
              this.isSave = true;
              setTimeout(() => { this.isSave = false; }, 5000);
            }
          });

      }
      else{
        alert("Please select RedAccount reason!");
      }
    }
  }

  getSVBaseRemarkHistory(accountNo: string) {
    this.isRemarkList = false;
    this.customHttpService.getDataWithAuthentication("/SR/GetSVBaseRemarkHistory?accountNo=" + accountNo).subscribe(data => {
      if (data.isSuccess) {
        if (data.responseData != null && data.responseData.length > 0) {
          console.log("Remark History", data);
          this.remarkHistory = data.responseData;
          this.isRemarkList = true;
        }
        else {
          this.isRemarkList = false;
        }

      }
    });

  }

  compareObjects(object1, object2, key) {
    const obj1 = object1[key].toUpperCase()
    const obj2 = object2[key].toUpperCase()

    if (obj1 < obj2) {
      return -1
    }
    if (obj1 > obj2) {
      return 1
    }
    return 0
  }

  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length > 0 || this.isList) && this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }
  checkIsLost() {
    return false;
    // if(this.srStatus=='Lost'||this.srStatus=='ShortClose'){
    //   return true;
    // }
    // else {
    // return false;
    // }
  }
  roundNumber(no: any) {
    return Math.round(no); //parseFloat(no).toFixed(2);
  }

  saveRemark(item) {

    this.customHttpService.postData("/SR/UpdateSVBaseRemark",
      {
        "Id": item.id,
        "ZRMRemark": item.zrmRemark,
        "RRMRemark": item.rrmRemark,
        "UserId": this.loggedinUserId
      }).subscribe(data => {
        if (data.isSuccess) {

        }
      });
  }

  onCheckboxChange(item: any) {
    debugger;
      item.isChange = true;
  }

  onDropdownChange(item: any){
    debugger;
    item.isChange = true;
  }

  onDescriptionChange(item: any){
    debugger;
    item.isChange = true;
  }

  saveAllRemark(){
    debugger;
    this.isAllSave = true;
    this.newData = this.customerItems.filter(a => a.isChange == true).map((item)=> (
      {
        "AccountNo": item.accountNo,
        "OrderNo": item.orderNo,
        "IsRedAccount": item.isRedAccount,
        "RedAccountReason": item.redAccountReason,
        "Description": item.description,
        "UserId": this.loggedinUserId
      }
    ) );
    //console.log("UpdatedData", this.newData);

    if(this.newData != null && this.newData.length > 0){
      debugger
      this.customHttpService.postData("/SR/SaveAllSVBaseRemark", this.newData).subscribe(data => {
        if (data.isSuccess) {
          alert("All Remark Saved!");
          console.log(data);
          this.isAllSave = true;
          setTimeout(() => { this.isAllSave = false; }, 5000);
        }
        else{
          alert("Something went wrong!");
        }
      });
    }
    else{
      this.isAllSave = false;
      alert("No Data Updated.");
    }

    
    

  }

  groupBy(array) {

    var distinctCustomerList = array.map(x => x.accountNo).filter((value, index, self) => self.indexOf(value) === index);
    let result = [];
    distinctCustomerList.forEach(function (a) {
      var individualCustomerList = [];
      individualCustomerList = array.filter(x => x.accountNo == a);
      result.push({
        regionName: individualCustomerList[0].regionName,
        scName: individualCustomerList[0].scName,
        accountName: individualCustomerList[0].accountName,
        cvValue: individualCustomerList.reduce(function (prev, cur) {
          return prev + cur.cvValue;
        }, 0),
        accountId: individualCustomerList[0].accountNo,
        accountNo: individualCustomerList[0].accountId,
        closedCV: individualCustomerList.filter(x => x.renewalTag.toLowerCase() != 'lost').reduce(function (prev, cur) {
          return prev + cur.cvValue;
        }, 0)
      });
    }, Object.create(null));

    console.log(result);
    return result;

  }
  size(ar) {
    var row_count = ar.length;
    var row_sizes = []
    for (var i = 0; i < row_count; i++) {
      console.log("ch" + ar[i].length);
      row_sizes.push(ar[i].length)
    }
    return [row_count, Math.min.apply(null, row_sizes)]
  }

  search(value: string): void {
    this.isList = false;
    console.log(this.customerItems);
    debugger;
    if (value.length >= 3) {
      console.log("test" + this.allPageOfItems[0].length);
      this.pageOfItems = this.allPageOfItems.filter((val) => val.regionName.toLowerCase().includes(value.toLowerCase()) ||
        (val.orderCreatedDisplayDate != null && val.orderCreatedDisplayDate.toLowerCase().includes(value.toLowerCase())) ||
        (val.scName != null && val.scName.toLowerCase().includes(value.toLowerCase())) ||
        (val.accountName != null && val.accountName.toLowerCase().includes(value.toLowerCase())) ||
        (val.accountNo != null && val.accountNo.toLowerCase().includes(value.toLowerCase())) ||
        (val.cvValue != null && val.cvValue.toString().includes(value)) ||
        (val.orderNno != null && val.orderNno.toLowerCase().includes(value.toLowerCase())) ||
        // (val.servicePlan != null && val.servicePlan.toLowerCase().includes(value.toLowerCase())) ||
        (val.serviceGroup != null && val.serviceGroup.toLowerCase().includes(value.toLowerCase())) ||
        (val.redAccountReason != null && val.redAccountReason.toLowerCase().includes(value.toLowerCase())) ||
        (val.description != null && val.description.toLowerCase().includes(value.toLowerCase())) ||
        (val.status != null && val.status.toLowerCase().includes(value.toLowerCase())));
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
    this.items = this.pageOfItems;

    if (this.customerItems.length > 0) {
      if (value.length >= 3) {
        this.customerItems = this.custPageOfItems.filter(c => c.accountName.toLowerCase().includes(value.toLowerCase()) ||
          (c.accountId != null && c.accountId.toLowerCase().includes(value.toLowerCase())) ||
          (c.regionName != null && c.regionName.toLowerCase().includes(value.toLowerCase())) ||
          (c.scName != null && c.scName.toLowerCase().includes(value.toLowerCase())) ||
          (c.cvValue != null && c.cvValue.toString().includes(value)));
        // (c.closedSV != null && c.closedSV.toString().includes(value)));
      }
      else {
        this.customerItems = this.custPageOfItems;
      }
    }
    else {
      this.customerItems = this.custPageOfItems;
    }

    if (this.accountItem.length > 0) {
      if (value.length >= 3) {
        this.accountItem = this.accountPageOfItem.filter(c => c.parentAccountNo.toLowerCase().includes(value.toLowerCase()) ||
          (c.parentAccountName != null && c.parentAccountName.toLowerCase().includes(value.toLowerCase())) ||
          (c.svValue != null && c.svValue.toString().includes(value)));
      }
      else {
        this.accountItem = this.accountPageOfItem;
      }
    }
    else {
      this.accountItem = this.accountPageOfItem;
    }
    this.isList = true;
  }

  sortCol(column: string, isASc: boolean, listType: string, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    var colName = "";

    console.log(event.srcElement.nodeName);
    if (event.srcElement.nodeName == 'SPAN') {
      var index = event.srcElement.innerHTML.indexOf("<i");
      colName = event.srcElement.innerHTML.substr(0, index);
      console.log(colName);
    }
    var sortedBy;
    if (isASc) {
      sortedBy = "Ascending";
    }
    else {
      sortedBy = "Descending";
    }

    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if (item.classList != undefined)
        item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    if (listType == 'detail') {
      this.commonSort(this.items, this.pageOfItems, column, isASc, dataTyp);
      this.sortText = colName + " (" + sortedBy + ")";
    }
    else if (listType == "summary") {
      this.commonSort(this.customerItems, this.custPageOfItems, column, isASc, dataTyp);
      this.sortText2 = colName + " (" + sortedBy + ")";
    }
    else {
      this.commonSort(this.accountItem, this.accountPageOfItem, column, isASc, dataTyp);
      this.sortText3 = colName + " (" + sortedBy + ")";
    }
  }


  commonSort(listItems: any[], pageOfItem: Array<any>, column: string, isASc: boolean, dataTyp?: any) {
    if (dataTyp != "date") {
      listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
      this.reverse = !this.reverse;
    }
    else {
      if (isASc == true) {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

      }
      else {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

      }
      this.reverse = !this.reverse
      listItems = pageOfItem;
    }
    this.onChangePage(listItems.slice(0, 20));
  }
  setActiveIndex(evt: any) {
    console.log("set index" + evt.nextId);
    this.activeIdString = evt.nextId;
  }

  exportToExcel() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");
    this.exportData = false;
    var excelTyp;

    if (this.activeIdString == "1") {
      excelTyp = "Detail";
    }
    if (this.activeIdString == "2") {
      excelTyp = "SiteWise";
    }
    if (this.activeIdString == "3") {
      excelTyp = "AccountWise";
    }

    this.customHttpService.exportDataWithAuthentication("/SR/ExportSRList?zrmId=" +
      zrmId + "&rmId=" + rmId + "&region=" + region + "&period=" + this.period + "&srStatus=" + this.srStatus + "&excelTyp=" + excelTyp)
      .subscribe(data => {
        console.log(data);

        this.exportData = true;
        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);

        var nowDate = formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = excelTyp + "_SVBase_" + this.srStatus + "_" + this.period + "_" + nowDate + ".xls";
        anchor.href = url;
        anchor.click();
      });

  }

}


function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}